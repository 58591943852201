import util from '@/libs/util.js'
import { AccountLogin } from '@api/sys.login'
import axios from 'axios'
import { Message } from 'element-ui'
import { getItem, removeItem, clearlocalForage } from '@/utils/localforageUtils';
const http = async (cfg1, cfg2) => {
  try {
    const res = await axios(cfg1, cfg2)
    return res
  } catch (err) {
    console.log(err)
    return {
      code: -1,
      msg: '网络请求失败,请稍后重试!'
    }
  }
}
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001' : 'https://api.node.soyin.cn';
export default {
  namespaced: true,
  state: {
    isChangeUser: false
  },
  mutations: {
    /**
     * @description 是否更换账户
     * @param {Object} value true: 是 false: 否
     */
    setIsChangeUser(state, value) {
      state.isChangeUser = value
    }
  },
  actions: {
    /**
     * @description 登录
     * @param {Object} param context
     * @param {Object} param vm {Object} vue 实例
     * @param {Object} param username {String} 用户账号
     * @param {Object} param password {String} 密码
     * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login({ dispatch }, {
      vm,
      username,
      password
    }) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        AccountLogin({
          username,
          password
        })
          .then(async res => {
            // 设置 cookie 一定要存 uuid 和 token 两个 cookie
            // 整个系统依赖这两个数据进行校验和存储
            // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
            // token 代表用户当前登录状态 建议在网络请求中携带 token
            // 如有必要 token 需要定时更新，默认保存一天
            util.cookies.set('uuid', res.uuid)
            util.cookies.set('token', res.token)
            // 设置 vuex 用户信息
            await dispatch('d2admin/user/set', {
              name: res.name
            }, { root: true })
            // 用户登录后从持久化数据加载一系列的设置
            await dispatch('load')
            // 结束
            resolve()
          })
          .catch(err => {
            console.log('err: ', err)
            reject(err)
          })
      })
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} param context
     * @param {Object} param vm {Object} vue 实例
     * @param {Object} param confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { vm, confirm = false }) {
      /**
       * @description 注销
       */
      async function logout() {
        // 清空 vuex 用户信息
        await dispatch('d2admin/user/set', {}, { root: true })
        // 跳转路由
        vm.$router.push({
          name: 'login'
        })
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        vm.$confirm('退出当前账号吗?  ', '确认操作', {
          confirmButtonText: '确定退出',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            const staffAuthToken = await getItem('staffAuthToken');
            const res = await http({
              method: 'POST',
              url: baseUrl + '/v1/api/weChat/logout',
              headers: {
                'staff-auth-token': staffAuthToken || ''
              }
            })
            if (res.data.code === 0) {
              await clearlocalForage();
              await removeItem('staffAuthToken')
              await removeItem('staffInfo')
              localStorage.clear();
              commit('d2admin/gray/set', false, { root: true })
              commit('d2admin/telephonySupport/setCompetence', false, { root: true })
              logout()
            } else {
              Message.error('注销登录失败,请重试!')
            }
          })
          .catch((e) => {
            commit('d2admin/gray/set', false, { root: true })
          })
      } else {
        logout()
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     */
    load({ commit, dispatch }) {
      return new Promise(async resolve => {
        // DB -> store 加载用户名
        await dispatch('d2admin/user/load', null, { root: true })
        // DB -> store 加载主题
        await dispatch('d2admin/theme/load', null, { root: true })
        // DB -> store 加载页面过渡效果设置
        await dispatch('d2admin/transition/load', null, { root: true })
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('d2admin/page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('d2admin/menu/asideCollapseLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('d2admin/size/load', null, { root: true })
        // end
        resolve()
      })
    },
    async changeAccount({ commit, dispatch }, { vm, confirm = false }) {
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        // 清空local
        await removeItem('isInterview')
        await removeItem('menu_buttons')
        await removeItem('staffInfo')
        await removeItem('staffAuthToken')
        await removeItem('companyMsg')
        vm.$router.push({
          path: '/fileForm/operation'
        })
      }
    },
  }
}
