/*
 * @Author: your name
 * @Date: 2021-10-20 09:41:52
 * @LastEditTime: 2022-01-05 17:12:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \soyin_node\sy-client\src\menu\modules\contractCompliance.js
 */
export default {
  path: '/ccm',
  title: '业务风控',
  // icon: 'none',
  iconfont: 'icon-menu-contract2-fill',
  children: (pre => [
    { path: `${pre}contractAssistant`, title: '合同风控小助手', icon: 'home',iconfont: 'icon-menu-ziyuan1' },
    { path: `${pre}contractFiling`, title: '合同履约', icon: 'home' ,iconfont: 'icon-menu-guidang'},
    { path: `${pre}cooperationRiskMonitoring`, title: '合作风险监控', icon: 'home' ,iconfont: 'icon-menu-guidang'},
    { path: `${pre}standardContractLibrary`, title: '公司标准合同库', icon: 'home' ,iconfont: 'icon-menu-guidangxiangmu'},
    { path: `${pre}electronicContract`, title: '签订电子合同', icon: 'home' ,iconfont: 'icon-menu-tongjifenxi1'},
    { path: `${pre}eb`, title: '企业背景调查', icon: 'home' ,iconfont: 'el-icon-data-analysis'},
    { path: `${pre}contractModel`, title: '合同模板管理', icon: 'home',iconfont: 'icon-menu-moban' },
    // {
    //   title: '表格',
    //   icon: 'table',
    //   children: [
    //     { path: `${pre}table/1`, title: '表格 1' }
    //   ]
    // },
    // {
    //   title: 'ISSUES',
    //   icon: 'github',
    //   children: [
    //     { path: `${pre}issues/142`, title: '#142' }
    //   ]
    // }
  ])('/ccm/')
}
