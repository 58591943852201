/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:52
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2024-03-20 16:18:03
 */
export default {
  path: '/work/lrc',
  title: '用工风控',
  // icon: 'none',
  iconfont: 'icon-menu-yewufengkong',
  children: (pre => [
    { path: `${pre}index`, title: '用工风控小助手', icon: '',iconfont: 'icon-menu-ziyuan1' },
    { // 招聘管理
      path: `${pre}recruit`, title: '招聘管理', icon: '',iconfont: 'el-icon-notebook-2',
      children: (pree => [
        { path: `${pree}interview`, title: '面试安排', icon: '', iconfont: 'icon-menu-guidangxiangmu'},
        { path: `${pree}JobAnalysis`, title: '招聘分析', icon: '', iconfont: 'icon-menu-tongjifenxi1' },
        { path: `${pree}humanResources`, title: '人才库', icon: '', iconfont: 'icon-menu-ziyuan7' },
      ])('/work/lrc/recruit/')
    }, 
    { // 员工管理
      path: `${pre}staff`, title: '员工管理', icon: '',iconfont: 'el-icon-star-off',
      children: (pree => [
        { path: `${pree}employee`, title: '员工花名册', icon: '', iconfont: 'icon-menu-ziyuan5' },
        { path: `${pree}caring`, title: '员工关怀', icon: '', iconfont: 'icon-menu-ziyuan4'},
        { path: `${pree}hrAnalysis`, title: '人资分析', icon: '', iconfont: 'icon-menu-tongjifenxi' }
      ])('/work/lrc/staff/')
    },
    
    { path: `${pre}electronicContract`, title: '合同管理', icon: '', iconfont: 'icon-menu-tongjifenxi1' },
    { path: `${pre}backtoneManage`, title: '背景调查', icon: '', iconfont: 'el-icon-data-analysis' },
    { path: `${pre}es/organization`,title: '组织架构',iconfont: 'icon-menu-zuzhiguanli'},
    {
      path: 'es',
      title: '人事设置',
      icon: '',
      iconfont: 'icon-menu-ziyuan2',
      children: (pre => [{
          path: `${pre}personnelProcess`,
          title: '人事流程',
          icon: 'none'
        },
        {
          path: `${pre}talentPool`,
          title: '人才分类',
          icon: 'none'
        },
        {
          path: `${pre}generalAgreement`,
          title: '通用协议配置',
          icon: 'none'
        },
        {
          path: `${pre}offerTemplate`,
          title: 'offer模板设置',
          icon: 'none'
        },
        // {
        //   path: `${pre}organization`,
        //   title: '组织架构管理',
        //   icon: 'none'
        // },
        // {
        //   path: `${pre}jobManagement`,
        //   title: '职位管理',
        //   icon: 'none'
        // }
      ])('/work/lrc/es/')
    }
  ])('/work/lrc/')
}