<template>
  <div id="app">
    <router-view style="min-width: 1450px !important" />
    <div v-if="showCallReminder">
      <callReminder
        @close="showCallReminder = false"
        :callNumber="callNumber"
        :callTime="callTime"
      ></callReminder>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import callReminder from "./components/callReminder/index";
export default {
  name: "app",
  components: {
    callReminder,
  },
  data() {
    return {
      $refreshStaffAuthToken: null,
      ws: null,
      wsUrl: "ws://127.0.0.1:8800/",
      showCallReminder: false,
      callNumber: "",
      callTime: "",
    };
  },
  computed: {
    ...mapState("d2admin/telephonySupport", [
      "competence",
      "reConnect",
      "msgRecord",
      "slot",
      "isCallIn",
      "lastRecord",
    ]),
  },
  watch: {
    isCallIn(v) {
      if (v) {
        this.callNumber = this.lastRecord.mainPhone;
        this.callTime = this.formatDataTime(new Date());
        this.showCallReminder = !this.showCallReminder;
      }
    },
    $route(to, from) {
      if (to.name === "follewSituation") {
        // 判断ws
        if (!this.ws) {
          this.setCompetence(true);
        }
      }
    },
    // competence(val) {
    //   if (val) {
    //     this.connect();
    //   }
    // },
    // reConnect() {
    //   if (val) {
    //     this.connect();
    //   }
    // },
    competence: {
      deep: true,
      handler: function (newV, oldV) {
        if (newV) {
          this.connect();
        }
      },
    },
    reConnect: {
      deep: true,
      handler: function (newV, oldV) {
        if (newV) {
          this.connect("reConn");
        }
      },
    },
  },
  async mounted() {
    let authStaffToken = await this.getValueItem("staffAuthToken");
    if (authStaffToken) {
      let buttons = (await this.getValueItem("menu_buttons")) || [];
      if (buttons.indexOf(10004) > -1 || buttons.indexOf(100060911) > -1) {
        this.keepCompetence();
      }
    }
    // this.calcFunc();
  },
  methods: {
    calcFunc() {
      const nums = [2, 0, 5, 0, 6, 3];
      const zeroArrs = [];
      for (let n of nums) {
        if (n === 0) {
          zeroArrs.unshift(n);
        } else {
          zeroArrs.push(n);
        }
      }
    },
    keepCompetence() {
      setInterval(async () => {
        if (!this.ws) {
          this.setCompetence(true);
        }
      }, 5000);
    },
    ...mapMutations("d2admin/telephonySupport", [
      "setWs",
      "setSimIndex",
      "setReConnect",
      "setCanCall",
      "setConnectionBroken",
      "setCompetence",
      "setSlotByNumber",
    ]),
    ...mapActions("d2admin/telephonySupport", [
      "dial",
      "hangUp",
      "autoRecord",
      "link",
      "getIccid",
      "getSimIndex",
      "receiveIccid",
      "calling",
      "stopRecord",
      "begRecord",
      "dialSuccess",
      "dialEnd",
      "dialThrough",
      "changeSimSuccess",
      "endChgSim",
      "sendMsg",
      "addMsgRecord",
    ]),
    connect(val) {
      //重连先关闭
      if (val == "reConn" && this.ws) {
        this.ws.close();
      }
      if ("WebSocket" in window) {
        var serverInfo = this.wsUrl;
        // 打开一个 web socket，全局共用一个
        this.ws = new WebSocket(serverInfo);
        console.log("已连接" + serverInfo);
        this.ws.onopen = () => {
          setTimeout(() => {
            // 设置重新连接为false
            this.setReConnect(false);
            // 设置全局ws
            this.setWs(this.ws);
            // 告诉话机连接成功
            this.link();
            // 默认设置话机自动录音
            this.autoRecord();
            // 获取话机电话信息
            this.getIccid();
            this.getSimIndex();
            this.setCanCall(true);
            this.setConnectionBroken(false);
          }, 100);
        };
        this.ws.onmessage = async (evt) => {
          var data = JSON.parse(evt.data);
          switch (data["cmd"]) {
            case "ATD":
              this.dialSuccess(data);
              break;
            // 服务端ping客户端
            case "USB":
              this.ws.send(
                '{"cmd":"USB","connected":"true","success":"true","message":"成功"}'
              );
              this.setConnectionBroken(!data.connected); // 连接是否断开
              break;
            case "CORG":
              this.dialSuccess(data);
              this.ws.send(
                '{"cmd":"CORG","number":"10010","success":"true","message":"成功"}'
              );
              break;
            case "CALLING":
              this.calling(data);
              break;
            case "CBEGIN":
              this.dialThrough(data);
              this.ws.send(
                '{"cmd":"CBEGIN","success":"true","message":"成功"}'
              );
              break;
            case "ALERT":
              this.ws.send('{"cmd":"ALERT","success":"true","message":"成功"}');
              break;
            case "CEND":
              this.dialEnd();
              this.ws.send('{"cmd":"CEND","success":"true","message":"成功"}');
              break;
            case "changeSim":
              this.changeSimSuccess(data);
              break;
            case "getIccid":
              this.receiveIccid(data);
              break;
            case "getSimIndex":
              this.setSimIndex(data.value);
              break;
            case "begrecord":
              this.begRecord(data);
              break;
            case "stoprecord":
              this.stopRecord(data);
              break;
            case "autorecord":
              break;
            case "endChgSim":
              this.endChgSim(data);
              break;
            case "sendmsg":
              // 创建短信记录
              let msgResp = data;
              this.addMsgRecord(msgResp);
              break;
          }
        };
        //出现错误
        this.ws.onerror = function (evt) {
          console.log("=========出现错误===============", evt);
          this.setConnectionBroken(true); // 连接是否断开
        };
        //连接断开
        this.ws.onclose = function (evt) {
          console.log("=========连接断开===============", evt);
          this.setReConnect(true);
          this.setConnectionBroken(true); // 连接是否断开
          this.connect();
        };
      } else {
        // 浏览器不支持 WebSocket
        alert("您的浏览器不支持 WebSocket!");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/style/public-class.scss";

.my-table {
  margin: 0 auto;

  table {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    table-layout: fixed;
    width: 100% !important;
  }

  table td,
  table th {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    padding: 3px 5px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    nowrap: false;
  }

  table th {
    border-bottom: 2px solid #333;
  }

  /* blockquote 样式 */
  blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }

  /* code 样式 */
  code {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #f1f1f1;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 3px;
  }

  pre code {
    display: block;
  }

  /* ul ol 样式 */
  ul,
  ol {
    margin: 10px 0 10px 20px;
  }
}

.theme-tomorrow-night-blue
  .d2-theme-container
  .d2-theme-container-main
  .d2-theme-container-main-body
  .container-component
  .d2-container-full
  .d2-container-full__body {
  background: #eee !important;
}

.el-page-header__content {
  font-size: 14px !important;
  color: #303133;
}

.caret-wrapper {
  width: 0px !important;
}

.box {
  display: flex;
  justify-content: space-between;
}

.boxWrap {
  display: flex;
  flex-wrap: wrap;
}

.boxNewLine {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dq {
  vertical-align: middle;
}

.shou {
  cursor: pointer;
}

.ib {
  display: inline-block;
}

.gridBox {
  display: grid;
}
.threeLimitedBox {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.twoLimitedBox {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.d2-layout-header-aside-group
  .d2-layout-header-aside-content
  .d2-theme-header
  .el-menu
  .el-menu-item {
  color: #909399 !important;
}

.theme-violet .d2-theme-header .el-menu .el-submenu .el-submenu__title {
  color: #909399 !important;
}

.box1 {
  width: auto;
}
.boxFlexStart {
  display: flex;
  justify-content: flex-start;
}

.el-message {
  top: 60px !important;
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.el-tooltip__popper.is-dark {
  background: #303133;
  color: #fff;
  height: auto;
  margin: 0 auto;
  word-break: break-all;
  line-height: 20px;
}

#sign-crumbs {
  margin: 0 auto;
  width: 100%;
}

#sign-crumbs ul {
  list-style: none;
  display: inline-table;
  width: 100%;
  padding: 0 3%;
}

#sign-crumbs ul li {
  display: inline;
}

#sign-crumbs ul li span {
  display: block;
  float: left;
  width: 22.5%;
  height: 50px;
  line-height: 50px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
  margin: 0 10px 0 0;
  font-size: 14px;
  text-decoration: none;
}

#sign-crumbs ul li span.current {
  background: #409eff;
  color: #fff;
}

#sign-crumbs ul li span:after {
  content: "";
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #f8f8f8;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 1;
}

#sign-crumbs ul li span.current:after {
  border-left: 20px solid #409eff;
}

#sign-crumbs ul li span:before {
  content: "";
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

#sign-crumbs ul li:first-child span {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#sign-crumbs ul li:first-child span:before {
  display: none;
}

#sign-crumbs ul li:last-child span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#sign-crumbs ul li:last-child span:after {
  display: none;
}
</style>
<style>
@import "~@/assets/style/quillCss.css";

.el-dialog {
  border-radius: 10px !important;
}

.el-tooltip__popper {
  max-width: 800px !important;
}
</style>
