/*
 * @Author: your name
 * @Date: 2021-10-20 09:41:52
 * @LastEditTime: 2022-11-10 11:40:49
 * @LastEditors: hpb95@sina.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \soyin_node\sy-client\src\menu\modules\customerMarketing.js
 */
export default {
  path: '/cm',
  title: '客户营销',
  icon: 'none',
  children: (pre => [
    { path: `${pre}companyAssistant`, title: '企业小助手', iconfont: 'icon-menu-tongjifenxi'},
    {
      path: `${pre}sea`, title: '企业公海', iconfont: 'icon-menu-icon-test',
       children: (pre =>  [
        { path: `${pre}companySea`, title: '企业公海', iconfont: 'icon-menu-icon-test'},
        { path: `${pre}discardSea`, title: '废弃公海', iconfont: 'icon-menu-icon-test'},
      ])('/cm/sea/')
    },
    { path: `${pre}companyMine`, title: '我的企业', iconfont: 'icon-menu-ziyuan7'},
    { path: `${pre}allEnterprise`, title: '全部企业', iconfont: 'icon-menu-ziyuan4'},
    { path: `${pre}achievementMore`, title: '数据统计', iconfont: 'el-icon-data-line'},
    { path: `${pre}performance`, title: '业绩管理', iconfont: 'el-icon-data-line'},
    { path: `${pre}turnApply`, title: '移交法务申请', iconfont: 'icon-menu-ziyuan6'},
    { path: `${pre}turnCheck`, title: '移交法务审核', iconfont: 'icon-menu-ziyuan5' },
    {
      path: `${pre}tr`, title: '电话录音', iconfont: 'el-icon-headset',
       children: (pre =>  [
        { path: `${pre}ter`, title: '通话记录' , iconfont: 'el-icon-phone-outline'},
        { path: `${pre}msr`, title: '短信记录', iconfont: 'icon-menu-maodunjiufen' },
        // { path: `${pre}mr`, title: '营销规则' }, 数据统计
        { path: `${pre}sc`, title: '分享通话' , iconfont: 'icon-menu-ziyuan4'},
      ])('/cm/tr/')
    },
    {
      path: `${pre}sr`, title: '销售雷达', iconfont: 'el-icon-discover',
      children: (pre =>  [
        { path: `${pre}d`, title: '资料库' , iconfont: 'el-icon-document'},
        { path: `${pre}mc`, title: '我的收藏' , iconfont: 'el-icon-price-tag'},
        { path: `${pre}ms`, title: '分享记录' , iconfont: 'el-icon-paperclip'},
        { path: `${pre}cr`, title: '查看记录' , iconfont: 'el-icon-view'},
        { path: `${pre}oc`, title: '在线咨询' , iconfont: 'el-icon-chat-dot-round'},
        { path: `${pre}dm`, title: '资料管理' , iconfont: 'el-icon-edit-outline'},
        { path: `${pre}sc`, title: '销售配置' , iconfont: 'el-icon-setting'}
      ])('/cm/sr/')
    },
	{
	  path: `${pre}speakingTechnique`, title: '话术库', iconfont: 'el-icon-chat-line-square',
	  children: (pre =>  [
	    { path: `${pre}enterprise`, title: '企业话术库' , iconfont: 'el-icon-reading'},
	    { path: `${pre}personal`, title: '个人话术库' , iconfont: 'el-icon-reading'},
	    { path: `${pre}audit`, title: '话术审核' , iconfont: 'el-icon-reading'},
	  ])('/cm/speakingTechnique/')
	},
    {
      path: `${pre}ers`, title: '企业风险调研' , iconfont: 'el-icon-document-checked'
    },
    {
      path: `${pre}op`, title: '营销设置' , iconfont: 'el-icon-setting',
      children: (pre =>  [
        { path: `${pre}tc`, title: '目标配置', iconfont: 'el-icon-set-up' },
        { path: `${pre}tt`, title: '目标模板' , iconfont: 'el-icon-wind-power'},
        { path: `${pre}mr`, title: '营销规则', iconfont: 'el-icon-s-tools' },
        { path: `${pre}sim`, title: '卡号配置' , iconfont: 'el-icon-phone'},
        { path: `${pre}ls`, title: '标签库' , iconfont: 'el-icon-document-remove'},
        { path: `${pre}group`, title: '分组管理', icon: 'group',iconfont: 'icon-menu-fenzu' }
      ])('/cm/op/')
    }
    // {
    //   title: '表格',
    //   icon: 'table',
    //   children: [
    //     { path: `${pre}table/1`, title: '表格 1' }
    //   ]
    // },
    // {
    //   title: 'ISSUES',
    //   icon: 'github',
    //   children: [
    //     { path: `${pre}issues/142`, title: '#142' }
    //   ]
    // }
  ])('/cm/')
}
