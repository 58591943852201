<template>
  <el-menu-item :index="menu.path || uniqueId">
    <!-- <d2-icon-svg class="icon" v-if="menu.icon" :name="menu.icon"/> -->
    <i v-if="menu.iconfont" :class="menu.iconfont" style="margin-left:60px"></i>
    <i v-if="menu.iconfont === undefined & !menu.iconSvg" class="fa fa-file-o"  ></i>
    <!-- <d2-icon-svg v-if="menu.iconSvg" :name="menu.iconSvg"/> -->
    <span   :class="{box3:menu.iconfont === undefined & !menu.iconSvg}" class="box2 title" slot="title" >{{menu.title || '未命名菜单'}}</span>
  </el-menu-item>
</template>

<script>
import { uniqueId } from 'lodash'
export default {
  name: 'd2-layout-header-aside-menu-item',
  props: {
    menu: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      uniqueId: uniqueId('d2-menu-empty-')
    }
  }
}
</script>
<style scoped>

  el-menu-item:hover {
    color: red;
    background: white!important;
  }
  /* .title:hover {
    color: red;
  } */
  .menuIcon {
    width: 16px;
    height: 16px;
    background: sienna;
    margin-right: 5px;
  }
  .box2{
	  margin-left: 20px;
  }
  .box3{
	  margin-left: 50px;
  }
</style>