<template>
  <div id="callReminder">
    <div class="call-box">
      <div class="header-box">
        <div style="width: 10%">
          <i class="el-icon-phone-outline" style="color: #fff"></i>
        </div>
        <div style="width: 80%">来电提醒</div>
        <div style="width: 10%" @click="$emit('close')">
          <i class="el-icon-circle-close" style="color: #fff"></i>
        </div>
      </div>
      <div style="padding: 20px 30px">
        <div class="tips">
          您于{{ callTime }}有一个来电，来电号码为{{
            callNumber
          }}，所对应系统客户如下，您可直接点击查看客户信息哟~
        </div>
        <div class="customers-box">
          <div style="color: #409eff; margin-bottom: 10px; cursor: pointer" v-for="item in customsList" :key="item.id"
            @click="goCustomer(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  props: {
    callNumber: {
      type: String,
      default: "",
    },
    callTime: {
      type: String,
      default: "",
    },
  },
  watch: {
    callNumber(v) {
      this.getCompanyCustomers();
    },
  },
  data() {
    return {
      customsList: [],
    };
  },
  created() {
    if (this.callNumber) {
      this.getCompanyCustomers();
    }
  },
  methods: {
    goCustomer(cusInfo) {
      this.$router.push(`/cm/index/follewSituation?customerId=${cusInfo.id}&type=2&curPage=1&pageType=my&companyMineActive=1`)
    },
    async getCompanyCustomers() {
      const cfg = {
        method: "GET",
        url: this.nodeUrl + `/v1/api/getCompanyCustomers`,
        params: {
          phoneNumber: this.callNumber
        }
      };
      const res = await this.$http(cfg);
      if (res.data.code === 0) {
        this.customsList = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>

<style scoped lang="less">
#callReminder {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background: #fff;
  box-shadow: 10px 10px 5px #888;
  border: 1px solid #888;

  .call-box {
    width: 327px;

    .header-box {
      width: 100%;
      background: #409eff;
      color: #fff;
      font-size: 20px;
      padding: 6px;
      display: flex;
      align-items: center;
    }
  }

  .tips {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px
  }

  .customers-box {
    margin-top: 20px;
    min-height: 250px;
    overflow-y: auto;
    max-height: 250px;
  }
}
</style>
