import axios from 'axios';
let http = async (cfg1, cfg2) => {
    try {
        let res = await axios(cfg1, cfg2)
        return res
    } catch (err) {
        console.log(err)
        return {
            code: -1,
            msg: '网络请求失败,请稍后重试!'
        }
    }
}
var OSS = require('ali-oss');

let baseUrl = ""
let oosUrl = ""
if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://127.0.0.1:7001'
    oosUrl = 'http://127.0.0.1:7001/'
} else {
    baseUrl = 'https://api.node.soyin.cn'
    oosUrl = "https://soyin.oss-cn-beijing.aliyuncs.com/"
}

export default {
    namespaced: true,
    state: { 
        cids: localStorage.getItem('cids') ? JSON.parse(localStorage.getItem('cids')) : [],
        ws: {},
        isCallIn: false, // 是否呼入
        lastRecord: {
            staffId: '',
            companyId: '',
            callId: '', // 通话记录 id
            type: 1,               
            state: 0, //接通状态  0:未接通  1:已接通
            dialTime: '',    // 拨打时间
            sTime: '',       // 接通时间
            eTime: '',       // 
            mainPhone: '',   // 主叫号码
            coverPhone: '',  // 被叫号码
            lengthMillisecond: '',   //时长毫秒
            url: '',
            contacts: "", // 联系人,
            contactsJob: "", // 联系职位
            directCall: false, // 是否直接拨打
        },
        msgRecord: {
            content: "",  // 发送内容
            from: "", // 发件人
            addressee: "",  // 收件人
            customerId: "",
            type: 1
        },
        connected: false,   // 链接电话状态
        competence: false, // 默认是没有权限 拿到用户的权限后 如果有权限 App.vue 监听 为true 则链接
        slot: [], // 卡槽
        simIndex: 0,
        reConnect: false,
        callOn: false, // 电话拨打成功开始计时
        callStop: false, // 电话结束
        canCall: false,  //是否可以拨打
        numbers: [],
        connectionBroken: false, // 连接是否断开
        staffAuthToken: "",
        soundRecording: false, // 录音是否生成成功
        cmRules: null,
        simStatus: true,
        credentials: {}, // 阿里云访问凭证
        uploadRoundTagError: false, // 上传录音文件是否成功
        localUrl: "", // 本地录音文件
        uploadTime: "0"
    },
    mutations: {
        setCids (state, cids) {
            localStorage.setItem('cids', JSON.stringify(cids))
            state.cids = cids;
        },
        /**
         * @description 记录 UA
         * @param {Object} state vuex state
        */
        setCredentials(state, credentials) {
            state.credentials = credentials
            clientOss = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                endpoint: "https://f.block.soyin.cn",
                region: 'oss-cn-beijing',
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: credentials.AccessKeyId,
                accessKeySecret: credentials.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: credentials.SecurityToken,
                // 填写Bucket名称，例如examplebucket。
                bucket: 'soyin',
                secure: true,
                cname: true
            });
        },
        setIsCallIn(state, value) {
            state.isCallIn = value
        },
        setUploadTime(state, value) {
            state.uploadTime = value
        },
        setUploadRoundTagError(state, value) {
            state.uploadRoundTagError = value
        },
        setCmRules(state, cmRules) {
            state.cmRules = cmRules
        },
        setDirectCallTow(state, directCall) {
            state.lastRecord.directCall = directCall
        },
        setStaffAuthToken(state, token) {
            state.staffAuthToken = token
        },
        setConnectionBroken(state, connectionBroken) {
            state.connectionBroken = connectionBroken
        },
        setNumbers(state, numbers) {
            state.numbers = numbers
        },
        setSoundRecording(state, soundRecording) {
            state.soundRecording = soundRecording
        },
        setCanCall(state, canCall) {
            state.canCall = canCall
        },
        setCallOn(state, callOn) {
            state.callOn = callOn
        },
        setCallStop(state, callStop) {
            state.callStop = callStop
        },
        setWs (state, ws) {
           state.ws = ws
        },
        updateLastRecord(state, record) {
            state.lastRecord = Object.assign(state.lastRecord, record);
        },
        setConnected (state, connected) {
           state.connected = connected
        },
        setReConnect(state, reConnect) {
            state.reConnect = reConnect
        },
        setCompetence(state, competence) {
            state.competence = competence
        },
        setSimStatus(state, value) {
            state.simStatus = value
        },
        setSlotByNumber(state, numbers = []) {
            let slots = state.slot
            if (slots.length <= 0) {
                state.connectionBroken = false
            } else {
                for (let i = 0; i < numbers.length; i++) {
                    for (let j = 0; j < slots.length; j++) {
                        if (state.slot[j].index === numbers[i].index) {
                            // 如果当前话机只有两张,去对应的实例卡号
                            state.slot[j].number = numbers[i].simNumber
                        }
                    }
                }

                for (let j = 0; j < slots.length; j++) {
                    if (slots[j].index === state.simIndex) {
                        state.lastRecord.mainPhone = slots[j].number
                    }
                }
            }
        },
        setSlot(state, slot) { // 0,1,0,0,0
            if (slot) {
                let numbers = state.numbers
                slot = slot.split(',')
                if (slot.length <= 0) {
                    state.connectionBroken = false
                } else {
                    let data = []
                    for (let i = 0; i < slot.length; i++) {
                        let item = slot[i]
                        if (Number(item) === 1) {
                            data.push({ name: 'sim' + (i + 1), number: '', index: (i + 1) })
                        }
                    }
                    if (data.length <= 0) {
                        state.canCall = false
                        return
                    }
                    for (let i = 0; i < numbers.length; i++) {
                        for (let j = 0; j < data.length; j++) {
                            if (data[j].index === numbers[i].index) {
                                // 如果当前话机只有两张,去对应的实例卡号
                                data[j].number = numbers[i].simNumber
                            }
                        }
                    }
                    state.slot = data
                }
            } else {
                return []
            }
        },
        setSimIndex(state, index) {
            state.simIndex = Number(index)
            
            // 设置主叫号码
            for (let j = 0; j < state.slot.length; j++) {
                if (state.slot[j].index === state.simIndex) {
                    state.lastRecord.mainPhone = state.slot[j].number
                }
            }
        },
        updateMsgRecord(state, record) {
            state.msgRecord = {
                content: record.content,  // 发送内容
                from: record.from, // 发件人
                addressee: record.addressee,  // 收件人
                customerId: record.customerId,
                type: record.type
            }
        }
    },
    actions: {
        setDirectCall({ state, commit, dispatch }, directCall) {
            commit('setDirectCallTow', directCall)
        },
        changeSim({ state, commit, dispatch }, index) {
            let keyword = `{"cmd": "changeSim","value": ${index},"status":0}`
            commit('setCanCall', false)
            state.ws.send(keyword);
        },
        changeSimSuccess({ state, commit, dispatch }, data) { // {cmd: "changeSim", message: "成功", status: 0, success: true, value: 2
            if (data.success) {
                commit('setSimIndex', data.value)
                commit('setSimStatus', true)
                commit('updateLastRecord', {
                    dialTime: '',
                    callId: '',
                    state: 0,
                    type: 1,
                    mainPhone: state.lastRecord.mainPhone,
                    coverPhone: data.number,
                    lengthMillisecond: 0,   //时长毫秒
                    url: '',
                })
            } else {
                commit('setSimStatus', false)
            }
        },
        endChgSim({ state, commit }, data) {
            commit('setCanCall', true)
            
            // 设置号码
            let slots = state.slot
            for (let j = 0; j < slots.length; j++) {
                if (slots[j].index === state.simIndex) {
                    state.lastRecord.mainPhone = slots[j].number
                }
            }
        },
        // 请求当前话机电话信息
        getIccid({ state, commit, dispatch }, data) {
            let keyword = '{"cmd": "getIccid"}'
            state.ws.send(keyword);
        },
        receiveIccid({ state, commit, dispatch }, data) { //{sim: "0,1,1,0,0,2,0", …}
            commit('setSlot', data.sim)
        },
        // 判断当前用哪个卡槽
        getSimIndex({ state, commit, dispatch }) {
            let keyword = '{"cmd": "getSimIndex"}'
            state.ws.send(keyword);
        }, 
        link({ state, commit, dispatch }) {
            let keyword = '{"cmd":"LINK"}'
            state.ws.send(keyword);
        },
        autoRecord({ state, commit, dispatch }) {
            let keyword = '{"cmd":"autorecord","value":"1"}'
            state.ws.send(keyword);
        },
        // 拨打号码
        dial ({ state, commit, dispatch }, data) { 
            let callid = 'sy_'+ new Date().getTime()
            let keyword = '{"cmd":"ATD","number":"'+data.number+'","hidden":"0","callid":"'+callid+'"}'; 
            state.ws.send(keyword);
            // // 设置当前记录 插入客户名称 公司id
            commit('updateLastRecord', {
                customerId: data.customerId,
                mainPhone: data.mainPhone,   // 主叫号码
                coverPhone: data.coverPhone,  // 被叫号码  
                contacts: data.contacts, // 联系人
                contactsJob: data.contactsJob,
                lengthMillisecond: 0,   //时长毫秒
                url: '',
            })
            commit('setIsCallIn', false)
            commit('setUploadTime', "0")
            state.callStop = false
        },
        async addMsgRecord({ state, commit, dispatch }, data) { // 创建短信记录
            let dataObj = {
                content: state.msgRecord.content,
                from: state.lastRecord.mainPhone,
                customerId: state.msgRecord.customerId,
                addressee: data.number,
                type: state.msgRecord.type 
            }
            // 上传通话记录 未接通
            let cfg1 = {
                method: "POST",
                url: baseUrl + '/v2/api/syEnterpriseMessageRecord/sendSyEnterpriseMessageRecord',
                headers: {
                    'staff-auth-token': state.staffAuthToken || ''
                },
                data: dataObj
            };
            let res = await http(cfg1);
            if (res.data.code !== 0) {
               // Message.error(res.data.msg)
            }
        },
        // 发送短信
        sendMsg({ state, commit, dispatch }, data) {
            // {"cmd":"sendmsg","number":"1","msg":"123456test 测试"} 服务发给 web： {"cmd":"sendmsg","message":"成功"} 备注： number：号码,msg 短信内容
            let keyword = `{"cmd":"sendmsg","number": "${data.number}", "msg": "${data.msg}"}`;
            commit('updateMsgRecord', {
                customerId: data.customerId,
                content: data.msg,  // 发送内容
                from: state.lastRecord.mainPhone, // 发件人
                addressee: data.number,  // 收件人
                type: data.type
            })
            state.ws.send(keyword);
        },
        // 电话拨打成功
        dialSuccess({ state, commit, dispatch }, data) {
            commit('setSlot', data.sim)
            // 设置当前记录
            commit('updateLastRecord', {
                dialTime: new Date().getTime(),
                callId: data.callId,
                state: 0,
                type: 1,
                mainPhone: state.lastRecord.mainPhone,
                coverPhone: data.number,
                lengthMillisecond: 0,   //时长毫秒
                url: '',
            })
            // 电话拨打成功计时
            state.callOn = true
        },
        // 电话接通
        async dialThrough({ state, commit, dispatch }, data) {
            // 设置当前记录
            commit('updateLastRecord', { // 来电时间
                state: 1,  
                sTime: new Date().getTime()                     
            })
        },
        // 通话结束
        async dialEnd({ state, commit, dispatch }, data) {
            // let lastRecord = state.lastRecord
            // if (!state.lastRecord.coverPhone) {
            //     commit('updateLastRecord', {
            //         coverPhone: data.number
            //     })
            // }
            commit('updateLastRecord', {
                eTime: new Date().getTime()
            })
            if (state.lastRecord.state === 0) {
                commit('updateLastRecord', { 
                    lengthMillisecond: 0,
                    url: ''
                })               
                let dataObj = {
                    type: state.lastRecord.type,
                    state: state.lastRecord.state,
                    sTime: state.lastRecord.dialTime,
                    eTime: state.lastRecord.eTime,
                    mainPhone: state.lastRecord.mainPhone,
                    coverPhone: state.lastRecord.coverPhone,
                    lengthMillisecond: 0,
                    url: "",
                    customerId: state.lastRecord.customerId,
                    contacts: state.lastRecord.contacts,
                    contactsJob: state.lastRecord.contactsJob
                }
                // 上传通话记录 未接通
                let cfg1 = {
                    method: "POST",
                    url: baseUrl + '/v2/api/syEneterprisePhoneRecord/addRecord',
                    headers: {
                        'staff-auth-token': state.staffAuthToken || ''
                    },
                    // eTime, mainPhone, coverPhone, lengthMillisecond, url, customerId
                    data: dataObj
                };
                let res = await http(cfg1);
                // if (res.data.code !== 0) {
                //     Message.error(res.data.msg)
                // }
                commit('updateLastRecord', { 
                    url: '',
                    lengthMillisecond: 0,
                    sTime: '',
                    eTime: '',
                    state: 0
                })
            } else {
                commit('updateLastRecord', { 
                    lengthMillisecond: state.lastRecord.sTime ? new Date().getTime() - state.lastRecord.sTime : 0
                })  
                // 有通话
                commit('setSoundRecording', true) 
                commit('setUploadTime', "1") 
            }
            commit('setIsCallIn', false)
            if (!state.isCallIn) {
                state.callStop = true
                state.callOn = false
            }
        },
        // 开始生成录音
        async begRecord({ state, commit, dispatch }, data) {
            commit('setUploadTime', "25") 
        },
        // 录音生成成功
        async stopRecord({ state, commit, dispatch }, data) { // {"callId":"production_out_10010_1617019647","cmd":"stoprecord","fileName":"C:/Users/Administrator/Desktop/山东卡尔KT1000(57D)中间件_V3.02.13/recordpath/production_out_10010_1617019647.mp3"}
            try {
                commit('setCanCall', false)
                commit('setUploadRoundTagError', false)
                commit('setUploadTime', "50") 
                state.localUrl = data.fileName
                // 传到后台 插入
                let cfg1 = {
                    method: "get",
                    url: 'http://127.0.0.1:8801', // 切勿改成ip地址
                    params: {
                        url: data.fileName,
                        token: state.staffAuthToken
                    }
                };
                let res1 = await http(cfg1);
                if (res1.data.code !== 0) {
                    commit('setSoundRecording', false)
                    commit('setUploadRoundTagError', true)
                } else {
                    commit('setUploadTime', "75") 
                    // 上传录音 返回url
                    commit('updateLastRecord', {
                        url: oosUrl + res1.data.data.key // 拼接路径
                    })
                    // 上传通话记录
                    let dataObj = {
                        type: state.lastRecord.type,
                        state: state.lastRecord.state,
                        sTime: state.lastRecord.dialTime,
                        eTime: state.lastRecord.eTime,
                        mainPhone: state.lastRecord.mainPhone,
                        coverPhone: state.lastRecord.coverPhone,
                        lengthMillisecond: state.lastRecord.lengthMillisecond,
                        url: state.lastRecord.url,
                        customerId: state.lastRecord.customerId,
                        contacts: state.lastRecord.contacts,
                        contactsJob: state.lastRecord.contactsJob
                    }
                    // 上传通话记录 已接通
                    let cfg2 = {
                        method: "POST",
                        url: baseUrl + '/v2/api/syEneterprisePhoneRecord/addRecord',
                        headers: {
                            'staff-auth-token': state.staffAuthToken || ''
                        },
                        data: dataObj
                    };
                    await http(cfg2);
                    commit('setUploadTime', "100") 
                    commit('setSoundRecording', false)
                    commit('setCanCall', true)
                    commit('setUploadRoundTagError', false)
                }
            } catch(e) {
                console.log(e)
                commit('updateLastRecord', { 
                    url: ''
                })
                commit('setSoundRecording', false)
                commit('setUploadRoundTagError', true)
            }
        },
        calling({ state, commit, dispatch }, data) { // 格式 {"callId":"production_in_18016681123_1617082575","cmd":"CALLING","number":"18016681123"}
            console.log(data, '========电话进来了===========')
            let slots = state.slot
            let numbers = state.numbers
            for (let i = 0; i < numbers.length; i++) {
                for (let j = 0; j < slots.length; j++) {
                    if (state.slot[j].index === numbers[i].index) {
                        // 如果当前话机只有两张,去对应的实例卡号
                        state.slot[j].number = numbers[i].simNumber
                    }
                }
            }

            for (let j = 0; j < slots.length; j++) {
                if (slots[j].index === state.simIndex) {
                    state.lastRecord.mainPhone = slots[j].number
                }
            }
            commit('updateLastRecord', { // 来电时间
                mainPhone: data.number,   // 主叫号码 (来电号码)
                coverPhone: state.lastRecord.mainPhone, //data.coverPhone,  // 被叫号码本机号码  
                dialTime: new Date().getTime(),
                callId: data.callId,
                state: 0,  
                type: 2,
                sTime: new Date().getTime(),
                lengthMillisecond: 0,
                url: ''                        
            })
            state.callStop = false
            commit('setIsCallIn', true)
            //呼入
        },

        // 挂掉电话
        hangUp({ state, commit, dispatch }) {
            let keyword = '{"cmd":"ATH"}'; 
            state.ws.send(keyword);
            state.callOn = false
            state.callStop = true
            commit('updateLastRecord', { // 来电时间
                lengthMillisecond: 0                          
            })
            commit('setIsCallIn', false)
        },
        hangUpSuccess() {}
    }
}
