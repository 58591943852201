<template>
  <div class="d2-layout-header-aside-menu-side background">
    <div
      class="d2-layout-header-aside-menu-empty"
      flex="dir:top main:center cross:center"
    >
      <!-- <d2-icon name="boy"/> -->
      <div class="leftTop">
        <div class="companyName">
          {{ companyName }}
        </div>
        <div class="headerBox">
          <img
            src="../../../../assets/image/default.png"
            style="width: 100%; height: 100%; border-radius: 60px"
            v-if="!user.headPortrait"
          />
          <img
            :src="$imgURL + user.headPortrait"
            style="width: 100%; height: 100%; border-radius: 60px"
            v-else
          />
        </div>
        <div class="userName">{{ user.staffName }}</div>
        <div class="cut_line">
          <img
            src="../../../../assets/slices/line.png"
            style="width: 100%; height: auto"
          />
        </div>
        <div style="color: white; font-size: 14px">
          {{ user.departmentName }}-{{ user.postName }}
        </div>
        <div class="saying">
          不从泥泞不堪的小道上迈步，就踏不上铺满鲜花的道路
        </div>
      </div>
    </div>
    <!-- <div v-if="aside.length === 0 && !asideCollapse" class="d2-layout-header-aside-menu-empty" flex="dir:top main:center cross:center">
      <d2-icon name="inbox"/>
      <span>欢迎您,{{userName}}</span>
    </div> -->
    <div class="left_menu_box">
      <el-menu
        :collapse="asideCollapse"
        :unique-opened="true"
        :default-active="active"
        ref="menu"
        @select="handleMenuSelect"
        class="menu_box test_2"
      >
        <template v-for="(menu, menuIndex) in aside">
          <d2-layout-header-aside-menu-item
            v-if="menu.children === undefined"
            :menu="menu"
            :key="menuIndex"
          />
         <d2-layout-header-aside-menu-sub
            v-else
            :menu="menu"
            :key="menuIndex"
          />
        </template>
      </el-menu>
    </div>
    <!-- <div class="bottom_box"></div> -->
    <div class="date_box">
      <div>
        <span style="font-size: 40px">{{ day }}</span
        ><span>{{ month }}</span
        >月
      </div>
      <div>{{ days }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import menuMixin from "../mixin/menu";
import d2LayoutMainMenuItem from "../components/menu-item/index.vue";
import d2LayoutMainMenuSub from "../components/menu-sub/index.vue";
import BScroll from "better-scroll";
export default {
  name: "d2-layout-header-aside-menu-side",
  mixins: [menuMixin],
  components: {
    "d2-layout-header-aside-menu-item": d2LayoutMainMenuItem,
    "d2-layout-header-aside-menu-sub": d2LayoutMainMenuSub,
  },
  data() {
    return {
      active: "",
      asideHeight: 300,
      BS: null,
      user: {},
      companyName: {},
      month: "",
      day: "",
      days: "",
    };
  },
  async created() {
    var that = this;
    var myDate = new Date();
    var month = myDate.getMonth() + 1; //月
    var day = myDate.getDate(); //日
    var days = myDate.getDay();
    switch (days) {
      case 1:
        that.days = "星期一";
        break;
      case 2:
        that.days = "星期二";
        break;
      case 3:
        that.days = "星期三";
        break;
      case 4:
        that.days = "星期四";
        break;
      case 5:
        that.days = "星期五";
        break;
      case 6:
        that.days = "星期六";
        break;
      case 0:
        that.days = "星期日";
        break;
    }
    this.month = month;
    this.day = day;
    this.user = await this.getValueItem("staffInfo");
    let company = await this.getValueItem("companyMsg");
    this.companyName = company ? company.companyName : '';
  },
  computed: {
    ...mapState("d2admin/menu", ["aside", "asideCollapse"]),
  },
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse(val) {
      this.scrollDestroy();
      setTimeout(() => {
        this.scrollInit();
      }, 500);
    },
    // 监听路由 控制侧边栏激活状态
    $route: {
      handler({ fullPath }) {
        this.active = fullPath;
		// bug 销售雷达前三个菜单第一次点击会选中话术库
		
        this.$nextTick(() => {
          if (this.aside.length > 0 && this.$refs.menu) {
            this.$refs.menu.activeIndex = fullPath;
          }
        });
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.scrollDestroy();
  },
  methods: {
    scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true,
        // 如果你愿意可以打开显示滚动条
        scrollbar: {
          fade: true,
          interactive: true,
        },
      });
    },
    scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy();
      } catch (e) {
        delete this.BS;
        this.BS = null;
      }
    },
  },
};
</script>

<style>
.headerBox {
  width: 120px;
  height: 120px;
  /* background: blue; */
  margin-bottom: 16px;
  border-radius: 60px;
}
.cut_line {
  margin-top: 8px;
}
.leftTop {
  height: 390px;
  background: url("../../../../assets/slices/left_top_bg.png") no-repeat;
  width: 100%;
  background-size: 100% 100%;
  /* background-color: white; */
  /* background-size: 100% 100%; */
  /* border: 1px solid blue; */
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 16px; */
  box-sizing: border-box;
  /* justify-content: center; */
}
.userName {
  font-size: 18px;
  /* font-weight: 600; */
  color: #fff;
}
.companyName {
  padding: 20px 0px;
  color: white;
  font-size: 18px;
}

.saying {
  font-size: 12px;
  width: 80%;
  padding: 12px 12px;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 8px;
  border-radius: 10px;
}
.left_menu_box {
  width: 100%;
  height: calc(100vh - 390px - 80px);
  background: white;
  box-sizing: border-box;
  padding: 0px 0px 16px;
  /* display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 14px!important; */
  /* border: 1px solid blue; */
  /* border-radius: 16px; */

  overflow: auto;
}
.test_2::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.test_2::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.test_2::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.menu_box {
  text-align: left;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.background {
  background: white;
}
.el-menu {
  box-sizing: border-box;
}
.el-menu-item {
  font-size: 14px;
}
/* .el-menu-item:hover {
  font-size: 16px;
  background-color: white;
  color: #3399ff !important;
} */
/* .theme-line .el-menu-item:hover {
  background-color: white!important;
} */
/* .theme-line .el-menu-item i:hover {
  background-color: white!important;
  color: #3399ff !important;
} */
/* .el-menu-item i:hover {
   color: #3399ff !important;
} */
.el-submenu__title {
  /* width: 150px; */
  font-size: 14px;
}
.el-submenu__title svg,.el-menu-item svg {
  width: 18px !important;
  height: 18px !important;
  margin-right: 5px !important;
  color: #3399ff !important;
}
.date_box {
  background: #fff;
  box-sizing: border-box;
  /* padding: 16px; */
  width: 100%;
  height: 80px;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: bottom;
  align-items: flex-end;
  color: #3399ff;
}
.bottom_box {
  width: 100%;
  height: 120px;
  background-color: #fff;
  
}
</style>
