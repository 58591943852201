export default {
  path: '/cy',
  title: '合同库',
  // icon: 'none',
  iconfont: 'icon-menu-xingzhengfawu',
  children: (pre => [
    { path: `${pre}index`, title: '合同库', iconfont: 'icon-menu-gaikuang'},
    { path: `${pre}mb`, title: '我的浏览',iconfont: 'icon-menu-risk'},
    { path: `${pre}mc`, title: '我的收藏',iconfont: 'icon-menu-fuwu'},
    { path: `${pre}md`, title: '我的下载',iconfont: 'icon-menu-zongjie'},
  ])('/cy/')
}
