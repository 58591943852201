// 设置文件
import setting from '@/setting.js'
import store from '@/store/index'
import { uniqueId } from 'lodash'
function supplementMenuPath (menu) {
  return menu.map(e => ({
    ...e,
    path: e.path || uniqueId('d2-menu-empty-'),
    ...e.children ? {
      children: supplementMenuPath(e.children)
    } : {}
  }))
}

var recursive = (aside, authorityArray) => { 
  let data = aside.filter((item, index) => {
    if (item.children && item.children.length > 0) {
      item.children = recursive(item.children, authorityArray)
    }
    if (authorityArray.indexOf(item.path.trim()) === -1) {
      return false
    } 
    return true
  })
  return data
}
export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse,
    buttons: [], // 拥有的权限按钮
    authorityArray: []
  },
  actions: {
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} state vuex state
     * @param {Boolean} collapse is collapse
     */
    asideCollapseSet ({ state, dispatch }, collapse) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = collapse
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * 切换侧边栏展开和收缩
     * @param {Object} state vuex state
     */
    asideCollapseToggle ({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = !state.asideCollapse
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * 从持久化数据读取侧边栏展开或者收缩
     * @param {Object} state vuex state
     */
    asideCollapseLoad ({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          defaultValue: setting.menu.asideCollapse,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    authorityArraySet({ state, dispatch }, menu) {
      return new Promise(async resolve => {
        // store 赋值
        await dispatch('d2admin/db/set', {
          dbName: 'menu_authority',
          path: 'menu.authority',
          value: menu,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    }
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    async headerSet (state, menu) {
      // store 赋值
      state.header = supplementMenuPath(menu)
      var header = state.header
      var authorityArray = await store.dispatch('d2admin/db/get', {
        dbName: 'menu_authority',
        path: 'menu.authority',
        defaultValue: [],
        user: true
      }, { root: true })
      let data = recursive(header, authorityArray)
      state.header = data
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    async asideSet (state, menu) {
      state.aside = supplementMenuPath(menu)
      var aside = state.aside
      var authorityArray = await store.dispatch('d2admin/db/get', {
        dbName: 'menu_authority',
        path: 'menu.authority',
        defaultValue: [],
        user: true
      }, { root: true })
      let data = recursive(aside, authorityArray)
      state.aside = data
    },
    async authorityArraySetGet (state, menu) {
      var authorityArray = await store.dispatch('d2admin/db/get', {
        dbName: 'menu_authority',
        path: 'menu.authority',
        defaultValue: [],
        user: true
      }, { root: true })
      state.authorityArray = authorityArray
    }
  }
}
