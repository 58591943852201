import { render, staticRenderFns } from "./d2-container-card.vue?vue&type=template&id=d31f1708"
import script from "./d2-container-card.vue?vue&type=script&lang=js"
export * from "./d2-container-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports