import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
// const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const meta = {
	auth: true
}

export default {
	path: '/enterprise',
	name: 'enterprise',
	meta: {
		auth: true,
		title: '企业中心'
	},
	redirect: {
		name: 'enterprise'
	},
	component: layoutHeaderAside,
	children: (pre => [{
			path: 'info',
			name: `${pre}info`,
			component: () => import('@/pages/enterprise/'),
			meta: {
				...meta,
				title: '企业信息'
			}
		},
		{
			path: 'added/oec',
			name: `${pre}oec`,
			component: () => import('@/pages/enterprise/openContract'),
			meta: {
				...meta,
				title: '注册电子签约账号'
			}
		},
		{
			path: 'added/ccn',
			name: `${pre}ccn`,
			component: () => import('@/pages/enterprise/changeCompanyName'),
			meta: {
				...meta,
				title: '更改企业名称'
			}
		},
		{
			path: 'added/iframePage',
			name: `${pre}iframePage`,
			component: () => import('@/pages/enterprise/iframePage'),
			meta: {
				...meta,
				title: '企业印章授权'
			}
		},
		{
			path: 'added/iframePageInfo',
			name: `${pre}iframePageInfo`,
			component: () => import('@/pages/enterprise/iframePageInfo'),
			meta: {
				...meta,
				title: '企业信息授权'
			}
		},
		{
			path: 'buyPackage',
			name: `${pre}buyPackage`,
			component: () => import('@/pages/enterprise/components/contractPackage'),
			meta: {
				...meta,
				title: '购买套餐'
			}
		},
		{
			path: 'system',
			name: `${pre}system`,
			component: () => import('@/pages/enterprise/system/'),
			redirect: {
				path: 'system/account'
			},
			children: [{
					path: 'account',
					name: `${pre}account`,
					component: () => import('@/pages/enterprise/system/account'),
					meta: {
						auth: true,
						title: '账号管理'
					},
				},
				{
					path: 'add',
					name: `${pre}add`,
					component: () => import('@/pages/enterprise/system/add'),
					meta: {
						auth: true,
						title: '编辑账号'
					},
				},
				{
					path: 'role',
					name: `${pre}role`,
					component: () => import('@/pages/enterprise/system/role'),
					meta: {
						auth: true,
						title: '角色管理'
					},
				},
			],
			meta: {
				...meta,
				title: '权限管理'
			}
		},
		{
			path: 'added',
			name: `${pre}added`,
			component: () => import('@/pages/enterprise/added/'),
			redirect: {
				path: 'added/elc'
			},
			children: [
				{
					path: 'elc',
					name: `${pre}elc`,
					component: () => import('@/pages/enterprise/added/elc'),
					meta: {
						auth: true,
						title: '电子合同'
					},
				},
				{
					path: 'msg',
					name: `${pre}msg`,
					component: () => import('@/pages/enterprise/added/msg'),
					meta: {
						auth: true,
						title: '短信服务'
					},
				},
			],
			meta: {
				...meta,
				title: '增值服务'
			}
		}
	])('enterprise-')
}
