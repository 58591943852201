import store from '@/store/index';
export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {},
    linkUrl: '', // 邀请链接参数
    requestNum: false, // 设置请求节流,防止post重复提交
    aliOssSTS: {
      AccessKeyId: null,
      AccessKeySecret: ""
    }
  },
  actions: {
    setRequestNum({ state, dispatch }, num) {
      return new Promise(async resolve => {
        // store 赋值
        state.requestNum = num
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.info',
          value: num,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    setAliOssSTS({ state, dispatch }, value) {
      return new Promise(async resolve => {
        // store 赋值
        state.aliOssSTS = value;
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.sts',
          value: value,
          user: true
        }, { root: true })
        // end
        resolve(true)
      })
    },
    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {*} info info
     */
    set ({ state, dispatch }, info) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = info
        // 持久化
        await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'user.info',
          value: info,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     */
    load ({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.info',
          defaultValue: {},
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    getAliOssSTS({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.aliOssSTS = await dispatch('d2admin/db/get', {
          dbName: 'sys',
          path: 'user.sts',
          defaultValue: null,
          user: true
        }, { root: true })
        // end
        resolve(state.aliOssSTS)
      })
    },
    /**
     * 存员工/面试者邀约链接
     */
    setLinkUrl({ state, dispatch }, linkUrl) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = await dispatch('d2admin/db/set', {
          dbName: 'sys',
          path: 'link.url',
          value: linkUrl,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
  },
  mutations: {
    async getLinkUrl(state) {
      var linkUrl = await store.dispatch('d2admin/db/get', {
        dbName: 'sys',
        path: 'link.url',
        defaultValue: '',
        user: true
      }, { root: true })
      state.linkUrl = linkUrl
    }
  }
}
