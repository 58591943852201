/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:52
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-06-30 14:37:55
 */
export default {
  path: '/ls',
  title: '法务服务',
  icon: 'none',
  children: (pre => [
    { path: `${pre}legalAssistant`, title: '法务小助手', iconfont: 'el-icon-s-help' },
    { path: `${pre}companyTurn`, title: '企业移交', iconfont: 'el-icon-notebook-2'},
    { path: `${pre}companyLibrary`, title: '企业库', iconfont: 'el-icon-notebook-2'},
    { path: `${pre}products`, title: '产品服务', iconfont: 'el-icon-notebook-2'},
    { path: `${pre}myCompany`, title: '我的企业', iconfont: 'el-icon-s-custom'},
    { 
      path: `${pre}ca`, title: '拖欠款催收', iconfont: 'el-icon-notebook-1',
      children: (pree => [
        { path: `${pree}/my`, title: '我负责的', icon: 'user', iconfont: 'el-icon-s-check' },
        { path: `${pree}/all`, title: '全部催收', icon: 'user', iconfont: 'el-icon-s-check' }
      ])('/ls/ca')
    },
    { path: `${pre}newDisputeManagement`, title: '纠纷管理', iconfont: 'el-icon-notebook-1'},
    { path: `${pre}ersToLegal`, title: '企业风险调研', iconfont: 'el-icon-notebook-2'},
    { path: `${pre}summaryStatistics`, title: '服务总结-数据统计', iconfont: 'el-icon-notebook-2'},
    { path: `${pre}terRecord`, title: '通话记录', iconfont: 'el-icon-phone-outline'},
    { path: `${pre}cardSetting`, title: '卡号设置', iconfont: 'el-icon-setting'},
    { path: `${pre}labels`, title: '标签库', iconfont: 'el-icon-document-remove'},
    { path: `${pre}paperwork`, title: '文书管理', iconfont: 'el-icon-edit-outline'},
    { path: `${pre}serviceManagement`, title: '服务管理', iconfont: 'el-icon-s-claim'},
    { path: `${pre}corporateRisk`, title: '企业风险调查', iconfont: 'el-icon-reading'},
    { path: `${pre}topicManagement`, title: '题目管理', iconfont: 'el-icon-tickets'},
    // {
    //   title: '表格',
    //   icon: 'table',
    //   children: [
    //     { path: `${pre}table/1`, title: '表格 1' }
    //   ]
    // },
    // {
    //   title: 'ISSUES',
    //   icon: 'github',
    //   children: [
    //     { path: `${pre}issues/142`, title: '#142' }
    //   ]
    // }
  ])('/ls/')
}
