/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:48
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-01-10 09:11:59
 */
// import util from '@/libs/util.js'

export default {
    namespaced: true,
    mutations: {
      /**
       * @description 显示版本信息
       * @param {Object} state state
       */
      versionShow() {
        // util.log.capsule('D2Admin', `v${process.env.VUE_APP_VERSION}`)
        // console.log('D2 Admin  https://github.com/d2-projects/d2-admin')
        // console.log('D2 Crud   https://github.com/d2-projects/d2-crud')
        // console.log('Document  https://d2.pub/zh/doc/d2-admin')
        // console.log('请不要吝啬您的 star，谢谢 ~')
      }
    }
  }
  