/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2022-07-06 10:54:36
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2024-07-02 16:06:42
 */
import axios from 'axios'
import store from '@/store/index';
import router from '../router'
import { Notification } from 'element-ui';
const md5 = require('js-md5');
import { getItem, removeItem } from '@/utils/localforageUtils';

axios.defaults.timeout = 20000;
let tipsCount = 0; // 拦截提示数量

/**
 * @description: 格式化年月日
 * @param {*} time 需要格式的时间
 * @return {*} 年与日
 */
const formatNYR = function (time) {
	if (!time) {
		return '-'
	}
	var date = new Date(time);
	var y = date.getFullYear();
	var m = date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate();
	d = d < 10 ? ('0' + d) : d;
	return y + '-' + m + '-' + d;
}

const fileAccept = '.jpg,.jpeg,.png,.mp3,.mp4,.doc,.docx,.pdf,.xlsx,.xls,.zip,.rar,.txt';

/**
 * @description: 处理格式化对象为空
 * @param {*} valueObject
 * @return {*}
 */
const validationIsEmpty = (valueObject) => {
	for (let v of Object.keys(valueObject)) {
		if (valueObject[v] === "" || valueObject[v] === undefined || valueObject[v] === null) {
			valueObject[v] = '-'
		}
	}
	return valueObject
}

/**
 * @description: 格式化年月日时分秒
 * @param {*} time 需要格式的时间
 * @return {*} 格式化年月日时分秒
 */
const formatDataTime = function (time) {
	if (!time) {
		return ''
	}
	var date = new Date(time);
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var strDate = date.getDate();
	var hour = date.getHours();
	var min = date.getMinutes();
	var second = date.getSeconds();
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	}
	if (hour >= 0 && hour <= 9) {
		hour = "0" + hour;
	}
	if (min >= 0 && min <= 9) {
		min = "0" + min;
	}
	if (second >= 0 && second <= 9) {
		second = "0" + second;
	}
	return (year + '-' + month + '-' + strDate + ' ' + hour + ':' + min + ':' + second);
}

/**
 * @description: 格式化年月日时分
 * @param {*} time 需要格式的时间
 * @return {*} 格式化年月日时分
 */
const formatMinTimeValue = (v) => {
	if (!v) {
		return '-'
	}
	var date = new Date(v);
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var strDate = date.getDate();
	var hour = date.getHours();
	var min = date.getMinutes();
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	}
	if (hour >= 0 && hour <= 9) {
		hour = "0" + hour;
	}
	if (min >= 0 && min <= 9) {
		min = "0" + min;
	}
	var currentdate = year.toString() + '-' + month + '-' + strDate + ' ' + hour + ':' + min
	return currentdate;
};

const rawSign = (args) => {
	var keys = Object.keys(args);
	keys = keys.sort()
	var newArgs = {};
	keys.forEach(function (key) {
		newArgs[key] = args[key];
	});
	var string = '';
	for (var k in newArgs) {
		string += '&' + k + '=' + newArgs[k];
	}
	string = string.substr(1);
	return string;
}

const paysignjsapi = (ret) => {
	var string = rawSign(ret);
	var key = 'wx864c8640b814f219';
	string = string + '&key=' + key;
	return md5(string).toUpperCase();
}


/**
 * @description: 公共请求器
 * @param {*} cfg1 对象 url. method, data
 * @param {*} cfg2 对象 url. method, data
 * @return {*} 返回数据
 */
const http = async (cfg1, cfg2) => {
	const authToken = await getItem('authToken');
	const staffAuthToken = await getItem('staffAuthToken');
	if (authToken && router.history.current.path != '/login') {
		if (cfg1.header) {
			cfg1.headers['staff-auth-token'] = staffAuthToken || '5401a8463b545ec14517dfbed6285c79'
			cfg1.headers['auth-token'] = authToken
			cfg1.headers['request-platform'] = 'pc'
			cfg1.headers['environment'] = 0 // 记得修改 测试环境为 1
		} else {
			cfg1.headers = {
				'staff-auth-token': staffAuthToken || '5401a8463b545ec14517dfbed6285c79',
				'auth-token': authToken,
				'environment': 0  // 记得修改 测试环境为 1
			}
		}
	}

	if (!cfg1.headers) {
		cfg1.headers = {}
	}

	const data = cfg1.method === 'POST' || cfg1.method === 'post' ? cfg1.data : cfg1.params;
	cfg1.headers['soyin-sign'] = paysignjsapi(data ? data : { time: new Date().getTime() })
	try {
		if (cfg1.method === 'POST' || cfg1.method === 'post') {
			const requestNum = store.state.d2admin.user.requestNum
			if (requestNum) {
				Notification({
					title: '请求过于频繁,请刷新重试',
					type: 'error',
				})
				return
			}
		}
		store.dispatch('d2admin/user/setRequestNum', true)
		let res = await axios(cfg1, cfg2)
		if (res.data.code === -101 || res.data.code === -100) { // 没有token, 登录超时
			if (res.data.code === -100) {
				store.dispatch('d2admin/user/setRequestNum', false);
				router.push('/fileForm/operation?isTime=' + new Date().getTime());
			} else if (res.data.code === -101) {
				const removeItems = [
					'authToken',
					'user',
					'companyMsg',
					'staffInfo',
					'companyId',
					'staffAuthToken',
					'isInterview'
				];
				for (let ri of removeItems) {
					await removeItem(`${ri}`)
				}
				router.push('/login');
			}
			tipsCount++
			if (tipsCount === 1) {
				Notification({
					title: res.data.msg,
					type: 'error',
				})
			}
		} else {
			tipsCount = 0
			store.dispatch('d2admin/user/setRequestNum', false)
			return res
		}
	} catch (err) {
		console.log(err)
		store.dispatch('d2admin/user/setRequestNum', false)
		return {
			code: -1,
			msg: '网络请求失败,请稍后重试!'
		}
	}
}

// 获取一个月后的日期
const getNextMonthDate = () => {
	let date = new Date();
	date.setMonth(new Date().getMonth() + 1);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	month = (month > 9) ? month : ("0" + month);
	let day = date.getDate();
	day = (day < 10) ? ("0" + day) : day;
	let today = year + "-" + month + "-" + day;
	return today
}

/**
 * 根据文件名后缀区分 文件类型
 *
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
function matchFileType(fileName) {
	// 后缀获取
	let suffix = '';
	// 获取类型结果
	let result = '';
	if (!fileName) return false;
	try {
		// 截取文件后缀
		suffix = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)
		// 文件后缀转小写，方便匹配
		suffix = suffix.toLowerCase()
	} catch (err) {
		suffix = '';
	}
	// fileName无后缀返回 false
	if (!suffix) {
		result = false;
		return result;
	}

	let fileTypeList = [
		// 图片类型
		{ 'typeName': 'image', 'types': ['png', 'jpg', 'jpeg', 'bmp', 'gif'] },
		// 文本类型
		{ 'typeName': 'txt', 'types': ['txt'] },
		// excel类型
		{ 'typeName': 'excel', 'types': ['xls', 'xlsx'] },
		{ 'typeName': 'word', 'types': ['doc', 'docx'] },
		{ 'typeName': 'pdf', 'types': ['pdf'] },
		{ 'typeName': 'ppt', 'types': ['ppt'] },
		// 视频类型
		{ 'typeName': 'video', 'types': ['mp4', 'm2v', 'mkv'] },
		// 音频
		{ 'typeName': 'radio', 'types': ['mp3', 'wav', 'wmv'] }
	]
	// let fileTypeList = ['image', 'txt', 'excel', 'word', 'pdf', 'video', 'radio']
	for (let i = 0; i < fileTypeList.length; i++) {
		const fileTypeItem = fileTypeList[i]
		const typeName = fileTypeItem.typeName
		const types = fileTypeItem.types
		result = types.some(function (item) {
			return item === suffix;
		});
		if (result) {
			return typeName
		}
	}
	return 'other'
}

/**
 * @desc 合并两张图片
 * @param {*} img1 图片1路径
 * @param {*} img2 图片2路径
 */
const composeImgs = (img1, img2) => {
	return new Promise((resolve, reject) => {
		try {
			// 1，先创建一个画布
			let canvas = document.createElement('canvas');
			//2，创建画笔
			const context = canvas.getContext('2d');
			//3，设置背景的宽高
			canvas.width = 280;
			canvas.height = 280;

			//第一张图片====================================
			const myImage1 = new Image();
			myImage1.src = img1;
			myImage1.crossOrigin = 'Anonymous';
			myImage1.onload = () => {
				context.drawImage(myImage1, 0, 0, 280, 280); // 设置坐标并绘制
				//第二张图片
				const myImage2 = new Image();
				myImage2.src = img2;
				myImage2.crossOrigin = 'Anonymous';
				myImage2.onload = () => {
					circleImgOne(context, myImage2, 75, 75, 130, 130, 200)
					const base64 = canvas.toDataURL('image/png'); //获取base64的图片流
					resolve(base64); // 成功之后返回图片路径
				};
			};
		} catch (e) {
			console.log(e);
			reject(false);
		}
	});
}

const circleImgOne = (ctx, img, x, y, w, h, r) => {
	if (w < 2 * r) r = w / 2;
	if (h < 2 * r) r = h / 2;
	ctx.beginPath();
	ctx.moveTo(x + r, y);
	ctx.arcTo(x + w, y, x + w, y + h, r);
	ctx.arcTo(x + w, y + h, x, y + h, r);
	ctx.arcTo(x, y + h, x, y, r);
	ctx.arcTo(x, y, x + w, y, r);
	ctx.closePath();
	ctx.clip();
	ctx.drawImage(img, x, y, w, h);
}

/**
 * @desc 在线预览 doc,docx, xls...
 */
const openMediaFile = (url = '') => {
	window.open(
		`https://view.officeapps.live.com/op/view.aspx?src=` + url,
		`_blank`
	);
}

/**
 * @desc 计算两个时间的差距
 */
/**
 * @desc 计算年龄
 * 计算年份->计算月份->计算天数
 *
 * @date 2015-09-22
 * @author WadeYu
 */

var getDiffYmdBetweenDate = function (sDate1, sDate2) {
	var fixDate = function (sDate) {
		var aD = sDate.split('-');
		for (var i = 0; i < aD.length; i++) {
			aD[i] = fixZero(parseInt(aD[i]));
		}
		return aD.join('-');
	};
	var fixZero = function (n) {
		return n < 10 ? '0' + n : n;
	};
	var fixInt = function (a) {
		for (var i = 0; i < a.length; i++) {
			a[i] = parseInt(a[i]);
		}
		return a;
	};
	var getMonthDays = function (y, m) {
		var aMonthDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if ((y % 400 == 0) || (y % 4 == 0 && y % 100 != 0)) {
			aMonthDays[2] = 29;
		}
		return aMonthDays[m];
	};
	var y = 0;
	var m = 0;
	var d = 0;
	var sTmp;
	var aTmp;
	sDate1 = fixDate(sDate1);
	sDate2 = fixDate(sDate2);
	if (sDate1 > sDate2) {
		sTmp = sDate2;
		sDate2 = sDate1;
		sDate1 = sTmp;
	}
	var aDate1 = sDate1.split('-');
	aDate1 = fixInt(aDate1);
	var aDate2 = sDate2.split('-');
	aDate2 = fixInt(aDate2);
	//计算相差的年份
	/*aTmp = [aDate1[0]+1,fixZero(aDate1[1]),fixZero(aDate1[2])];
	while(aTmp.join('-') <= sDate2){
		y++;
		aTmp[0]++;
	}*/
	y = aDate2[0] - aDate1[0];
	if (sDate2.replace(aDate2[0], '') < sDate1.replace(aDate1[0], '')) {
		y = y - 1;
	}
	//计算月份
	aTmp = [aDate1[0] + y, aDate1[1], fixZero(aDate1[2])];
	while (true) {
		if (aTmp[1] == 12) {
			aTmp[0]++;
			aTmp[1] = 1;
		} else {
			aTmp[1]++;
		}
		if (([aTmp[0], fixZero(aTmp[1]), aTmp[2]]).join('-') <= sDate2) {
			m++;
		} else {
			break;
		}
	}
	//计算天数
	aTmp = [aDate1[0] + y, aDate1[1] + m, aDate1[2]];
	if (aTmp[1] > 12) {
		aTmp[0]++;
		aTmp[1] -= 12;
	}
	while (true) {
		if (aTmp[2] == getMonthDays(aTmp[0], aTmp[1])) {
			if (aTmp[1] == 12) {
				aTmp[0]++;
				aTmp[1] = 1;
				aTmp[2] = 1;
			} else {
				aTmp[1]++;
				aTmp[2] = 1;
			}
		} else {
			aTmp[2]++;
		}
		sTmp = ([aTmp[0], fixZero(aTmp[1]), fixZero(aTmp[2])]).join('-');
		if (sTmp <= sDate2) {
			d++;
		} else {
			break;
		}
	}
	return { y: y, m: m, d: d };
};

/**
 * @desc 获取两个日期的相差天数
 */
const getDaysApart = (date_val, date_vals) => {
	// 计算两个日期之间的差值
	let diffDateMsec,
		dateMsec_1 = Date.parse(date_val),
		dateMsec_2 = Date.parse(date_vals)
	// 将两个日期都转换为毫秒格式，然后相减
	diffDateMsec = Math.abs(dateMsec_1 - dateMsec_2) // 取相差毫秒数的绝对值
	return Math.floor(diffDateMsec / (1000 * 3600 * 24)) // 向下取整相差的天数
}

export default {
	http: http,
	axios: axios,
	formatNYR: formatNYR,
	fileAccept: fileAccept,
	validationIsEmpty: validationIsEmpty,
	formatDataTime: formatDataTime,
	getNextMonthDate: getNextMonthDate,
	formatMinTimeValue: formatMinTimeValue,
	matchFileType: matchFileType,
	composeImgs: composeImgs,
	openMediaFile: openMediaFile,
	getDiffYmdBetweenDate: getDiffYmdBetweenDate,
	getDaysApart: getDaysApart
}