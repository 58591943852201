export default {
  	path: 'es',
  	title: '人事设置',
  	icon: 'shield',
  	children: (pre => [{
  			path: `${pre}personnelProcess`,
  			title: '人事流程',
  			icon: 'none'
  		},
  		{
  			path: `${pre}talentPool`,
  			title: '人才分类',
  			icon: 'none'
		},
		{
			path: `${pre}generalAgreement`,
			title: '通用协议配置',
			icon: 'none'
		},
  		// {
  		// 	path: `${pre}organization`,
  		// 	title: '组织架构管理',
  		// 	icon: 'none'
  		// },
  		// {
  		// 	path: `${pre}jobManagement`,
  		// 	title: '职位管理',
  		// 	icon: 'none'
  		// },

  	])('/work/lrc/es')
  }
