import layoutHeaderAside from '@/layout/header-aside'

const meta = {
	auth: true
}

export default {
	path: '/work/lrc',
	name: 'work-lrc',
	meta: {
		auth: true,
		title: '用工风控'
	},
	redirect: {
		name: 'work-lrc-index'
	},
	component: layoutHeaderAside,
	children: (pre => [{
			path: 'index',
			name: `用工风控小助手`,
			component: () => import('@/pages/work/LaborRiskControl/index'),
			meta: {
				auth: true,
				title: '用工风控小助手'
			}
		},
		{
			path: 'cm',
				name: 'cm',
				meta: {
					auth: true,
					title: '企业信息'
				},
				hidden: true,
				component: () => import('@/pages/index/companyMsg/index'),
			},
		{
			path: 'calendar',
			name: `${pre}calendar`,
			component: () => import('@/pages/work/LaborRiskControl/calendar'),
			meta: {
				auth: true,
				title: '日历'
			}
		},
		{
			path: 'riskKnowledge',
			name: `${pre}riskKnowledge`,
			component: () => import('@/pages/work/LaborRiskControl/riskKnowledge'),
			meta: {
				auth: true,
				title: '风控小知识'
			}
		},
		{
			path: 'articleDetial',
			name: `${pre}articleDetial`,
			component: () => import('@/pages/work/LaborRiskControl/articleDetial'),
			meta: {
				auth: true,
				title: '文章详情'
			}
		},
		{
			path: 'backlog',
			name: `${pre}backlog`,
			component: () => import('@/pages/work/LaborRiskControl/backlog'),
			meta: {
				auth: true,
				title: '待办任务'
			}
		},
        // 招聘管理
		{
			path: 'recruit',
			name: `${pre}recruit`,
			component: () => import('@/pages/work/recruit/'),
			redirect: {
				path: 'recruit/interview'
			},
			meta: {
				auth: true,
				title: '招聘管理'
			},
            children: [
				{
					path: 'interview',
					name: `${pre}interview`,
					component: () => import('@/pages/work/LaborRiskControl/interview'),
					meta: {
						auth: true,
						title: '面试安排'
					}
				},
				{
					path: 'interview/backtone',
					name: `${pre}backtone`,
					component: () => import('@/pages/work/recruit/backtone'),
					meta: {
						auth: true,
						title: '编辑背景调查'
					}
				},
				{
			        path: 'interview/sendOffer',
			        name: `${pre}humanResources`,
			        component: () => import('@/pages/work/LaborRiskControl/interview/sendOffer'),
			        meta: {
				        auth: true,
				        title: '发送Offer'
			        }
		        },
				{
					path: 'JobAnalysis',
					name: `${pre}JobAnalysis`,
					component: () => import('@/pages/work/LaborRiskControl/JobAnalysis'),
					meta: {
						auth: true,
						title: '招聘分析'
					}
				},
				{
					path: 'staffMsg/resources',
					name: `${pre}staffMsg`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg/resources'),
					meta: {
						auth: true,
						title: '资源库人员信息'
					}
				},
				{
					path: 'staffMsg/interview',
					name: `${pre}staffMsg`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg/interview'),
					meta: {
						auth: true,
						title: '面试人员信息'
					}
				},
		        {
			        path: 'humanResources',
			        name: `${pre}humanResources`,
			        component: () => import('@/pages/work/LaborRiskControl/humanResources'),
			        meta: {
				        auth: true,
				        title: '人才库'
			        }
		        }
			]
		},
		{ // 员工管理
			path: 'staff',
			name: `${pre}staff`,
			component: () => import('@/pages/work/staff/'),
			redirect: {
				path: 'staff/employee'
			},
			children: [
				{
					path: 'hrAnalysis',
					name: `${pre}hrAnalysis`,
					component: () => import('@/pages/work/LaborRiskControl/hrAnalysis'),
					meta: {
						auth: true,
						title: '人资分析'
					}
				},
				{
					path: 'employee',
					name: `${pre}employee`,
					component: () => import('@/pages/work/LaborRiskControl/employee/index'),
					meta: {
						auth: true,
						title: '员工花名册',
					}
				},
				{
					path: 'importStaff',
					name: `${pre}importStaff`,
					component: () => import('@/pages/work/LaborRiskControl/employee/importStaff'),
					meta: {
						auth: true,
						title: '导入花名册',
					}
				},
				{
					path: 'openElectronicContract',
					name: `${pre}openElectronicContract`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg/openElectronicContract'),
					meta: {
						auth: true,
						title: '注册电子签约账号'
					}
				},
				{
					path: 'sendSingeContract',
					name: `${pre}sendSingeContract`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg/sendSingeContract'),
					meta: {
						auth: true,
						title: '发起签订'
					}
				},
				{
					path: 'signCustomFile',
					name: `${pre}signCustomFile`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg/signCustomFile'),
					meta: {
						auth: true,
						title: '发起自定义文件签订'
					}
				},
				{
					path: 'staffMsg',
					name: `${pre}staffMsg`,
					component: () => import('@/pages/work/LaborRiskControl/staffMsg'),
					meta: {
						auth: true,
						title: '花名册人员信息'
					}
				},
				{
					path: 'caring',
					name: `${pre}caring`,
					component: () => import('@/pages/work/LaborRiskControl/caring'),
					meta: {
						auth: true,
						title: '员工关怀'
					}
				},
			],
			meta: {
				auth: true,
				title: '员工管理'
			},
		},
		{ // 发起背景调查
			path: 'sendBacktone',
			name: `${pre}sendBacktone`,
			component: () => import('@/pages/work/backtone/sendBacktone'),
			meta: {
				auth: true,
				title: '发起背景调查'
			}
		},

		{ // 背景调查
			path: 'backtoneManage',
			name: `${pre}staff`,
			component: () => import('@/pages/work/LaborRiskControl/backtonePage'),
			meta: {
				auth: true,
				title: '背景调查'
			}
		},
		
		{
			path: 'electronicContract',
			name: `${pre}electronicContract`,
			component: () => import('@/pages/work/LaborRiskControl/electronicContract'),
			meta: {
				auth: true,
				title: '合同管理'
			},
			redirect: {
				name: `${pre}list`
			},
			children: [
				{
					path: 'list',
					name: `${pre}list`,
					component: () => import('@/pages/work/LaborRiskControl/electronicContract/list'),
					meta: {
						auth: true,
						title: '合同管理列表'
					},
				},
				{
					path: 'details',
					name: `${pre}details`,
					component: () => import('@/pages/work/LaborRiskControl/electronicContract/details'),
					meta: {
						auth: true,
						title: '合同详情'
					},
				},
				{
					path: 'reSendContract',
					name: `${pre}reSendContract`,
					component: () => import('@/pages/work/LaborRiskControl/electronicContract/reSignContract'),
					meta: {
						auth: true,
						title: '重新签署电子合同'
					},
				},
				{
					path: 'otherSignContract',
					name: `${pre}otherSignContract`,
					component: () => import('@/pages/work/LaborRiskControl/electronicContract/otherSignContract'),
					meta: {
						auth: true,
						title: '签署自定义电子合同'
					},
				},
				{
					path: 'reOtherSignContract',
					name: `${pre}otherSignContract`,
					component: () => import('@/pages/work/LaborRiskControl/electronicContract/reOtherSignContract'),
					meta: {
						auth: true,
						title: '重新签署自定义电子合同'
					},
				}
			]
		},
		{
			path: 'es',
			meta: {
				auth: true,
				title: '人事设置',
			},
			component: () => import('@/pages/work/employmentSettings'),
			// redirect: {
			// 	name: 'employmentSettings'
			// },
			//component: layoutHeaderAside,
			children: [
				{
					path: 'personnelProcess',
					name: '人才流程配置',
					meta: {
						auth: true,
						title: '人才流程配置',
					},
					component: () => import('@/pages/work/employmentSettings/personnelProcess'),
				},
				{
					path: 'talentPool',
					name: '人才分类',
					meta: {
						auth: true,
						title: '人才分类',
					},
					component: () => import('@/pages/work/employmentSettings/talentPool'),
				},
				{
					path: 'offerTemplate',
					name: 'offer模板',
					meta: {
						auth: true,
						title: 'offer模板',
					},
					component: () => import('@/pages/work/offerTemplate'),
				},
				{
					path: 'addOfferTemplate',
					name: 'offer模板设置',
					meta: {
						auth: true,
						title: 'offer模板设置',
					},
					component: () => import('@/pages/work/offerTemplate/add'),
				},
				{
					path: 'generalAgreement',
					name: '通用协议配置',
					meta: {
						auth: true,
						title: '通用协议配置',
					},
					component: () => import('@/pages/work/employmentSettings/generalAgreement'),
				},
				{
					path: 'addGeneralAgreement',
					name: '添加通用协议',
					meta: {
						auth: true,
						title: '添加通用协议',
					},
					component: () => import('@/pages/work/employmentSettings/addGeneralAgeement'),
				},
				{
					path: 'organization',
					name: '组织架构管理',
					meta: {
						auth: true,
						title: '组织架构管理',
					},
					component: () => import('@/pages/work/employmentSettings/organization'),
				},
				{
					path: 'jobManagement',
					name: '职位管理',
					meta: {
						auth: true,
						title: '职位管理',
					},
					component: () => import('@/pages/work/employmentSettings/jobManagement'),
				},
			]
		},
	])('work-lrc-')
}
