/*
 * 公共下载附件模板
 */
import Vue from 'vue'
const downloadKeyFile = (key) => { 
    return new Promise(async (resolve, reject) => {
        const http = Vue.prototype.$http
        const url = Vue.prototype.nodeUrl
        const res = await http({
            method: 'POST',
            url: url + '/v2/api/contractAttachment/downloadContractAttachment',
            data: {
                key: key
            },
        })
        if (res.data.code === 0) {
            resolve(res.data.url)
        } else {
            reject(res.data.msg)
        }
    })
}

export default {
    downloadKeyFile
}