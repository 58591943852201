export default {
    path: '/pc',
    title: '个人中心',
    // icon: 'none',
    iconfont: 'icon-menu-user',
    children: (pre => [
        { path: `${pre}center`, title: '个人中心', icon: 'user', iconfont: 'icon-menu-quanxian' },
        { path: `${pre}interview`, title: '个人中心', icon: 'user', iconfont: 'icon-menu-quanxian' },
    ])('/pc/')
  }
    