// import playground from './modules/playground'
// import frame from './modules/frame'
// import d2Crud from './modules/d2-crud'
// import plugins from './modules/plugins'
// import charts from './modules/charts'
// import components from './modules/components'
// import element from './modules/element'
// import filters from './modules/filters'
// import business from './modules/business'
import layoutHeaderAside from '@/layout/header-aside'
import riskControl from './modules/riskControl'
import systemMannage from './modules/systemMannage'
import cly from './modules/cly'
import enterprise from './modules/enterprise'
/**
 * 在主框架内显示
 */
const frameIn = [
	{
		path: '/',
		redirect: {
			path: '/work/lrc/index'
		},
		component: layoutHeaderAside,
		children: [
			// 首页 必须 name:index
			{
				path: 'index',
				name: 'index',
				meta: {
					auth: true,
					name: "首页"
				},
				component: () => import('@/pages/index'),
			},
			// {
			// 	path: 'demo',
			// 	name: 'demo',
			// 	meta: {
			// 		auth: true,
			// 		name: "DEMO"
			// 	},
			// 	component: () => import('@/pages/demo'),
			// },
			// {
			// 	path: '/interview',
			// 	component: () => import('@/pages/index/interview'),
			// 	children: [
			// 		{
			// 		  path: 'staffMsg',
			// 		  component: () => import('@/pages/work/LaborRiskControl/staffMsg'),
			// 		  meta: {
			// 			auth: true,
			// 		    title: '个人信息'
			// 		  }
			// 		},
			// 	]
			// },
			// {
			// 	path: '/submitIntview',
			// 	name: 'submitIntview',
			// 	component: () => import('@/pages/index/submitIntview'),
			// 	meta: { 
			// 		auth: true
			// 	 }
				
			// },
			// {
			// 	path: 'companyMsg/view',
			// 	name: 'companyMsg/view',
			// 	hidden: true,
			// 	component: () => import('@/pages/index/companyMsg/view'),
			// },
			// {
			// 	path: 'personalMsg',
			// 	name: 'personalMsg',
			// 	hidden: true,
			// 	component: () => import('@/pages/index/personalMsg'),
			// },
			// {
			// 	path: 'personalMsg/interViewDetails',
			// 	name: 'personalMsg/interViewDetails',
			// 	hidden: true,
			// 	component: () => import('@/pages/index/personalMsg/interViewDetails'),
			// },
			// 刷新页面 必须保留
			{
				path: 'refresh',
				name: 'refresh',
				hidden: true,
				component: {
					beforeRouteEnter(to, from, next) {
						next(vm => vm.$router.replace(from.fullPath))
					},
					render: h => h()
				}
			},
			// 页面重定向 必须保留
			{
				path: 'redirect',
				name: 'redirect',
				hidden: true,
				component: {
					beforeRouteEnter(to, from, next) {
						next(vm => vm.$router.replace(JSON.parse(from.params.route)))
					},
					render: h => h()
				}
			},
		]
	},
	//合同履约
	{
		path: '/ccm',
		meta: {
			auth: true,
			title: '业务风控',
		},
		redirect: {
			name: 'contractCompliance'
		},
		component: layoutHeaderAside,
		children: [
			{
				path: 'contractAssistant',
				name: '合同风控小助手',
				meta: {
					auth: true,
					title: '合同风控小助手',
				},
				component: () => import('@/pages/contractCompliance/contractAssistant'),
			},
			{
				path: 'cooperationRiskMonitoring',
				name: '合作风险监控',
				meta: {
					auth: true,
					title: '合作风险监控',
				},
				component: () => import('@/pages/contractCompliance/cooperationRiskMonitoring'),
			},
			{
				path: 'riskMonitoring',
				name: '风险监控',
				meta: {
					auth: true,
					title: '风险监控',
				},
				component: () => import('@/pages/contractCompliance/riskMonitoring'),
			},
			{
				path: 'checKMore',
				name: '合同风控-查看更多',
				meta: {
					auth: true,
					title: '查看更多',
				},
				component: () => import('@/pages/contractCompliance/checKMore'),
			},
			{
				path: 'contractFiling',
				name: '合同履约',
				meta: {
					auth: true,
					title: '合同履约',
				},
				component: () => import('@/pages/contractCompliance/contractFiling'),
			},
			{
				path: 'addContractFiling',
				name: '添加合同履约',
				meta: {
					auth: true,
					title: '添加合同履约',
				},
				component: () => import('@/pages/contractCompliance/addContractFiling'),
			},
			{
				path: 'editContract',
				name: '编辑合同',
				meta: {
					auth: true,
					title: '编辑合同',
				},
				component: () => import('@/pages/contractCompliance/editContract'),
			},
			{
				path: 'contractDetail',
				name: '合同详情',
				meta: {
					auth: true,
					title: '合同详情',
				},
				component: () => import('@/pages/contractCompliance/contractDetail'),
			},
			{
				path: 'standardContractLibrary',
				name: '标准合同库',
				meta: {
					auth: true,
					title: '标准合同库',
				},
				component: () => import('@/pages/contractCompliance/standardContractLibrary'),
			},
			{
				path: 'sendContract',
				name: '发起签订',
				meta: {
					auth: true,
					title: '发起签订',
				},
				component: () => import('@/pages/contractCompliance/sendContract'),
			},
			{
				path: 'otherSendContract',
				name: '发起电子合同签订',
				meta: {
					auth: true,
					title: '发起电子合同签订',
				},
				component: () => import('@/pages/contractCompliance/electronicContract/otherSign/sendContract'),
			},
			{
				path: 'reOtherSendContract',
				name: '重新发起电子合同签订',
				meta: {
					auth: true,
					title: '重新发起电子合同签订',
				},
				component: () => import('@/pages/contractCompliance/electronicContract/otherSign/reSendContract'),
			},
			{
				path: 'contractModel',
				name: '合同模板管理',
				meta: {
					auth: true,
					title: '合同模板管理',
				},
				component: () => import('@/pages/contractCompliance/contractModel'),
			},
			{ // 背景调查
				path: 'eb',
				name: `enterprisebacktoneManage`,
				component: () => import('@/pages/contractCompliance/backtonePage'),
				redirect: {
					path: 'eb/send'
				},
				meta: {
					auth: true,
					title: '企业背景调查'
				},
				children: [,
					{
						path: 'send',
						name: `enterprisebacktonesend`,
						component: () => import('@/pages/contractCompliance/backtonePage/send'),
						meta: {
							auth: true,
							title: '企业背景调查列表'
						}
					},
					{
						path: 'add',
						name: `enterprisebacktoneAdd`,
						component: () => import('@/pages/contractCompliance/backtonePage/add'),
						meta: {
							auth: true,
							title: '添加企业背景调查'
						}
					}
				]
			},
			{
				path: 'electronicContract',
				name: `electronicContract`,
				component: () => import('@/pages/contractCompliance/electronicContract'),
				meta: {
					auth: true,
					title: '签订电子合同'
				},
				redirect: {
					name: `electronicContractList`
				},
				children: [
					{
						path: 'list',
						name: `electronicContractList`,
						component: () => import('@/pages/contractCompliance/electronicContract/list'),
						meta: {
							auth: true,
							title: '电子合同列表'
						},
					},
					{
						path: 'details',
						name: `electronicContractDetails`,
						component: () => import('@/pages/contractCompliance/electronicContract/details'),
						meta: {
							auth: true,
							title: '电子合同详情'
						},
					},
					{
						path: 'reSendContract',
						name: `electronicContractReSendContract`,
						component: () => import('@/pages/contractCompliance/electronicContract/reSignContract'),
						meta: {
							auth: true,
							title: '重新签署电子合同'
						},
					}
				]
			},
		]
	},
	cly,
	//客户营销
	{
		path: '/cm',
		meta: {
			auth: true,
			title: '客户营销',
		},
		redirect: {
			name: 'customerMarketing'
		},
		component: layoutHeaderAside,
		children: [
			{
				path: 'companyAssistant',
				name: '企业小助手',
				meta: {
					auth: true,
					title: '企业小助手',
				},
				component: () => import('@/pages/customerMarketing/companyAssistant'),
			},
			{
				path: 'checkMore',
				name: '查看更多',
				meta: {
					auth: true,
					title: '查看更多',
				},
				component: () => import('@/pages/customerMarketing/checkMore'),
			},
			{
				path: 'achievementMore',
				name: '数据统计',
				meta: {
					auth: true,
					title: '数据统计',
				},
				component: () => import('@/pages/customerMarketing/achievementMore'),
			},
			{
				path: 'performance',
				name: '业绩管理',
				meta: {
					auth: true,
					title: '业绩管理',
				},
				component: () => import('@/pages/customerMarketing/performance'),
			},
			{
				path: 'amn',
				name: '更多业绩',
				meta: {
					auth: true,
					title: '更多业绩',
				},
				component: () => import('@/pages/customerMarketing/amn'),
			},
			{
				path: 'sea/companySea',
				name: '企业公海',
				meta: {
					auth: true,
					title: '企业公海',
				},
				component: () => import('@/pages/customerMarketing/companySea')
			},
			{
				path: 'sea/discardSea',
				name: '废弃公海',
				meta: {
					auth: true,
					title: '废弃公海',
				},
				component: () => import('@/pages/customerMarketing/discardSea')
			},
			{
				path: 'companySeaEdit',
				name: '企业公海编辑',
				meta: {
					auth: true,
					title: '企业公海编辑',
				},
				component: () => import('@/pages/customerMarketing/companySeaEdit'),
			},
			{
				path: 'companySeaCheck',
				name: '企业公海查看',
				meta: {
					auth: true,
					title: '企业公海查看',
				},
				component: () => import('@/pages/customerMarketing/companySeaCheck'),
			},
			{
				path: 'importExcel',
				name: '导入Excel',
				meta: {
					auth: true,
					title: '导入Excel',
				},
				component: () => import('@/pages/customerMarketing/importExcel'),
			},
			{
				path: 'companyMine',
				name: '客户营销-我的企业',
				meta: {
					auth: true,
					title: '我的企业',
				},
				component: () => import('@/pages/customerMarketing/companyMine'),
			},
			{
				path: 'allEnterprise',
				name: '全部企业',
				meta: {
					auth: true,
					title: '全部企业',
				},
				component: () => import('@/pages/customerMarketing/allEnterprise'),
			},
			{
				path: 'companyMineTurn',
				name: '移交法务',
				meta: {
					auth: true,
					title: '移交法务'
				},
				component: () => import('@/pages/customerMarketing/companyMineTurn'),
			},
			{
				path: 'serviceDetial',
				name: '服务详情',
				meta: {
					auth: true,
					title: '服务详情'
				},
				component: () => import('@/pages/customerMarketing/serviceDetial'),
			},
			{
				path: 'turnApply',
				name: '移交法务申请',
				meta: {
					auth: true,
					title: '移交法务申请'
				},
				component: () => import('@/pages/customerMarketing/turnApply'),
			},
			{
				path: 'turnCheck',
				name: '移交法务审核',
				meta: {
					auth: true,
					title: '移交法务审核'
				},
				component: () => import('@/pages/customerMarketing/turnCheck'),
			},
			{
				path: 'index',
				name: '企业信息',
				meta: {
					auth: true,
					title: '企业信息'
				},
				redirect: {
					name: "follewSituation"
				},
				component: () => import('@/pages/customerMarketing/index'),
				children: [
					{
						path: 'follewSituation',
						name: 'follewSituation',
						meta: {
							auth: true,
							title: '跟进情况',
						},
						component: () => import('@/pages/customerMarketing/follewSituation'),
					},
					{
						path: 'cms',
						name: 'cms',
						meta: {
							auth: true,
							title: '产品与服务记录'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/serviceRecord'),
					},
					{
						path: 'cp',
						name: 'cp',
						meta: {
							auth: true,
							title: '客户档案'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/customerProfile'),
					},
					{
						path: 'dm',
						name: 'dm',
						meta: {
							auth: true,
							title: '纠纷管理'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/disputeManagement'),
					},
					{
						path: 'ia',
						name: 'ia',
						meta: {
							auth: true,
							title: '面谈预约'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/interviewAppointment'),
					},
					{
						path: 'sy',
						name: 'sy',
						meta: {
							auth: true,
							title: '服务总结'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/serviceSummary'),
					},
					{
						path: 'er',
						name: 'er',
						meta: {
							auth: true,
							title: '企业风险调查'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/ersToNew'),
					},

					{
						path: 'rap',
						name: 'rap',
						meta: {
							auth: true,
							title: '风险规避计划'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/riskAversionPlan'),
					}
				]
			},
			{
				path: 'tr',
				name: '电话录音',
				meta: {
					auth: true,
					title: '电话录音'
				},
				component: () => import('@/pages/customerMarketing/teleRecord/'),
				children: [
					{
						path: '/cm/tr/ter',
						name: '客户营销-通话记录',
						meta: {
							auth: true,
							title: '通话记录'
						},
						component: () => import('@/pages/customerMarketing/teleRecord/terRecord'),
					},
					{
						path: '/cm/tr/msr',
						name: '短信记录',
						meta: {
							auth: true,
							title: '短信记录'
						},
						component: () => import('@/pages/customerMarketing/teleRecord/msgRecord'),
					},
					{
						path: '/cm/tr/sc',
						name: '分享通话',
						meta: {
							auth: true,
							title: '短信记录'
						},
						component: () => import('@/pages/customerMarketing/teleRecord/shareTer'),
					},
				]
			},
			{
				path: 'sr',
				name: '销售雷达',
				meta: {
					auth: true,
					title: '销售雷达'
				},
				component: () => import('@/pages/customerMarketing/radarStore/'),
				children: [
					{
						path: '/cm/sr/d',
						name: '资料库',
						meta: {
							auth: true,
							title: '资料库'
						},
						component: () => import('@/pages/customerMarketing/radarStore/database'),
					},
					{
						path: '/cm/sr/mc',
						name: '我收藏的',
						meta: {
							auth: true,
							title: '我收藏的'
						},
						component: () => import('@/pages/customerMarketing/radarStore/mc'),
					},
					{
						path: '/cm/sr/ms',
						name: '分享记录',
						meta: {
							auth: true,
							title: '分享记录'
						},
						component: () => import('@/pages/customerMarketing/radarStore/ms'),
					},
					{
						path: '/cm/sr/cr',
						name: '查看记录',
						meta: {
							auth: true,
							title: '查看记录'
						},
						component: () => import('@/pages/customerMarketing/radarStore/cr'),
					},
					{
						path: '/cm/sr/oc',
						name: '在线咨询',
						meta: {
							auth: true,
							title: '在线咨询'
						},
						component: () => import('@/pages/customerMarketing/radarStore/oc'),
					},
					{
						path: '/cm/sr/dm',
						name: '资料管理',
						meta: {
							auth: true,
							title: '资料管理'
						},
						component: () => import('@/pages/customerMarketing/radarStore/dataManagement'),
					},
					{
						path: '/cm/sr/sc',
						name: '销售配置',
						meta: {
							auth: true,
							title: '销售配置'
						},
						component: () => import('@/pages/customerMarketing/radarStore/staffConfig'),
					}
				]
			},
			{
				path: 'speakingTechnique',
				name: '话术库',
				meta: {
					auth: true,
					title: '话术库'
				},
				component: () => import('@/pages/customerMarketing/speakingTechnique/'),
				children: [
					{
						path: '/cm/speakingTechnique/enterprise',
						name: '企业话术库',
						meta: {
							auth: true,
							title: '企业话术库'
						},
						component: () => import('@/pages/customerMarketing/speakingTechnique/enterprise'),
					},
					{
						path: '/cm/speakingTechnique/personal',
						name: '个人话术库',
						meta: {
							auth: true,
							title: '个人话术库'
						},
						component: () => import('@/pages/customerMarketing/speakingTechnique/personal'),
					},
					{
						path: '/cm/speakingTechnique/audit',
						name: '话术审核',
						meta: {
							auth: true,
							title: '话术审核'
						},
						component: () => import('@/pages/customerMarketing/speakingTechnique/audit'),
					}
				]
			},
			{
				path: 'op',
				name: '营销设置',
				meta: {
					auth: true,
					title: '营销设置'
				},
				component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage'),
				children: [
					{
						path: '/cm/op/tc',
						name: '目标配置',
						meta: {
							auth: true,
							title: '目标配置'
						},
						component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage/targetConfig'),
					},
					{
						path: '/cm/op/tt',
						name: '目标模板',
						meta: {
							auth: true,
							title: '目标模板'
						},
						component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage/targetTemplate'),
					},
					{
						path: '/cm/op/mr',
						name: '营销规则',
						meta: {
							auth: true,
							title: '营销规则'
						},
						component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage/marketingRules'),
					},
					{
						path: '/cm/op/sim',
						name: '客户营销-卡号配置',
						meta: {
							auth: true,
							title: '卡号配置'
						},
						component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage/simOptions'),
					},
					{
						path: '/cm/op/ls',
						name: '客户营销-标签库',
						meta: {
							auth: true,
							title: '标签库'
						},
						component: () => import('@/pages/customerMarketing/marketingOptions/optionsPage/labels'),
					},
					{
						path: '/cm/op/group',
						name: `group`,
						component:() => import('@/pages/system/group'),
						meta: {
							auth: true,
							title: '分组管理'
						}
					},
				]
			},
			{
				path: 'ers',
				name: '客户营销-企业风险调研',
				meta: {
					auth: true,
					title: '企业风险调研'
				},
				component: () => import('@/pages/customerMarketing/ers'),
			}
		]
	},
	// 法务服务
	{
		path: '/ls',
		meta: {
			auth: true,
			title: '法务服务'
		},
		component: layoutHeaderAside,
		children: [
			{
				path: 'legalAssistant',
				component: () => import('@/pages/legalServices/index'),
				meta: {
					auth: true,
					title: '法务小助手'
				},
				redirect: {
					name: "法务小助手"
				},
				children: [
					{
						path: 'legalAssistantIndex',
						name: "法务小助手",
						component: () => import('@/pages/legalServices/legalAssistant/legalAssistant'),
					},
					{
						path: 'legalAssistantCenter',
						meta: {
							auth: true,
							title: '待办事项'
						},
						component: () => import('@/pages/legalServices/legalAssistant/index'),
					}
				]
			},
			{
				path: 'calendarAlone',
				name: '法务小助手/日历',
				meta: {
					auth: true,
					title: '法务小助手/日历'
				},
				component: () => import('@/pages/legalServices/calendar_alone'),
			},
			{
				path: 'dealClock',
				name: '待办提醒',
				meta: {
					auth: true,
					title: '待办提醒'
				},
				component: () => import('@/pages/legalServices/dealClock'),
			},
			{
				path: 'companyTurn',
				name: '企业移交',
				meta: {
					auth: true,
					title: '企业移交'
				},
				component: () => import('@/pages/legalServices/companyTurn'),
			},
			{
				path: 'companyLibrary',
				name: '企业库',
				meta: {
					auth: true,
					title: '企业库'
				},
				component: () => import('@/pages/legalServices/companyLibrary'),
			},
			{
				path: 'products',
				name: '产品服务',
				meta: {
					auth: true,
					title: '产品服务'
				},
				component: () => import('@/pages/legalServices/products'),
			},
			{
				path: 'myCompany',
				name: '法务服务-我的企业',
				meta: {
					auth: true,
					title: '我的企业'
				},
				component: () => import('@/pages/legalServices/myCompany'),
			},
			{
				path: 'ca',
				name: '法务服务-拖欠款催收',
				meta: {
					auth: true,
					title: '拖欠款催收'
				},
				children: [
					{
						path: 'my',
						meta: {
							auth: true,
							title: '我负责的'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/collectionArrears/index'),
					},
					{
						path: 'all',
						meta: {
							auth: true,
							title: '全部催收'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/collectionArrears/all'),
					}
				],
				component: () => import('@/pages/legalServices/sevuceDetialPages/collectionArrears/father'),
			},
			{
				path: 'caDetails',
				name: '法务服务-拖欠款催收详情',
				meta: {
					auth: true,
					title: '拖欠款催收详情'
				},
				component: () => import('@/pages/legalServices/sevuceDetialPages/collectionArrears/details'),
			},
			{
				path: 'ersToLegal',
				name: '法务服务-企业风险调研',
				meta: {
					auth: true,
					title: '企业风险调研'
				},
				component: () => import('@/pages/legalServices/ersToLegal'),
			},
			{
				path: 'summaryStatistics',
				name: '服务总结-数据统计',
				meta: {
					auth: true,
					title: '服务总结-数据统计'
				},
				component: () => import('@/pages/legalServices/serviceSummaryStatistics'),
			},
			{
				path: 'disputeManagementOther',
				name: 'disputeManagementOther',
				meta: {
					auth: true,
					title: '纠纷管理'
				},
				component: () => import('@/pages/legalServices/sevuceDetialPages/disputeManagementOther'),
			},
			{
				path: 'newDisputeManagement',
				name: 'newDisputeManagement',
				meta: {
					auth: true,
					title: '纠纷管理'
				},
				component: () => import('@/pages/legalServices/sevuceDetialPages/newDisputeManagement'),
			},
			{
				path: 'serviceDetial',
				name: '服务信息',
				redirect: {
					name: "serviceRecord"
				},
				meta: {
					auth: true,
					title: '服务信息'
				},
				component: () => import('@/pages/legalServices/serviceDetial'),
				children: [
					{
						path: 'serviceOverview',
						name: 'serviceOverview',
						meta: {
							auth: true,
							title: '服务概况'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/serviceOverview'),
					},
					{
						path: 'serviceRecord',
						name: 'serviceRecord',
						meta: {
							auth: true,
							title: '产品与服务记录'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/serviceRecord'),
					},
					{
						path: 'customerProfile',
						name: 'customerProfile',
						meta: {
							auth: true,
							title: '客户档案'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/customerProfile'),
					},
					{
						path: 'disputeManagement',
						name: 'disputeManagement',
						meta: {
							auth: true,
							title: '纠纷管理'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/disputeManagement'),
					},
					{
						path: 'interviewAppointment',
						name: 'interviewAppointment',
						meta: {
							auth: true,
							title: '面谈预约'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/interviewAppointment'),
					},
					{
						path: 'salesNotes',
						name: 'salesNotes',
						meta: {
							auth: true,
							title: '销售小记'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/salesNotes'),
					},
					{
						path: 'serviceSummary',
						name: 'serviceSummary',
						meta: {
							auth: true,
							title: '服务总结'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/serviceSummary'),
					},
					{
						path: 'enterpriseRiskSurvey',
						name: 'enterpriseRiskSurvey',
						meta: {
							auth: true,
							title: '企业风险调查'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/ersToNew'),
					},
					{
						path: 'riskAversionPlan',
						name: 'riskAversionPlan',
						meta: {
							auth: true,
							title: '风险规避计划'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/riskAversionPlan'),
					},
					{
						path: 'returnVisit',
						name: 'returnVisit',
						meta: {
							auth: true,
							title: '回访'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/returnVisit'),
					},
					{
						path: 'ca',
						name: 'ca',
						meta: {
							auth: true,
							title: '拖欠款催收'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/ca'),
					},
					{
						path: 'visit',
						name: 'visit',
						meta: {
							auth: true,
							title: '上门拜访'
						},
						component: () => import('@/pages/legalServices/sevuceDetialPages/visit'),
					}
				]
			},
			{
				path: 'serviceDetialEdit',
				name: '添加/编辑服务记录',
				meta: {
					auth: true,
					title: '添加/编辑服务记录'
				},
				component: () => import('@/pages/legalServices/serviceDetialEdit'),
			},
			{
				path: 'serviceCycle',
				name: '添加/编辑服务记录',
				meta: {
					auth: true,
					title: '添加/编辑服务记录'
				},
				component: () => import('@/pages/legalServices/sevuceDetialPages/serviceCycle'),
			},
			{
				path: 'disputeRecorder',
				name: '添加/编辑纠纷记录',
				meta: {
					auth: true,
					title: '添加/编辑纠纷记录'
				},
				component: () => import('@/pages/legalServices/disputeRecorder'),
			},
			{
				path: 'disputeRecorderCheck',
				name: '查看纠纷记录',
				meta: {
					auth: true,
					title: '查看纠纷记录'
				},
				component: () => import('@/pages/legalServices/disputeRecorderCheck'),
			},
			{
				path: 'paperwork',
				name: '文书管理',
				meta: {
					auth: true,
					title: '文书管理'
				},
				component: () => import('@/pages/legalServices/paperwork'),
			},
			{
				path: 'serviceManagement',
				name: '服务管理',
				meta: {
					auth: true,
					title: '服务管理'
				},
				component: () => import('@/pages/legalServices/serviceManagement'),
			},
			{
				path: 'addServiceManagement',
				name: '添加服务管理',
				meta: {
					auth: true,
					title: '添加服务管理'
				},
				component: () => import('@/pages/legalServices/addServiceManagement'),
			},
			{
				path: 'corporateRisk',
				name: '企业风险调查',
				meta: {
					auth: true,
					title: '企业风险调查'
				},
				component: () => import('@/pages/legalServices/corporateRisk'),
			},
			{
				path: 'topicManagement',
				name: '题目管理',
				meta: {
					auth: true,
					title: '题目管理'
				},
				component: () => import('@/pages/legalServices/topicManagement'),
			},
			{
				path: 'labels',
				name: '法务服务-标签库',
				meta: {
					auth: true,
					title: '标签库'
				},
				component: () => import('@/pages/legalServices/labels'),
			},
			{
				path: 'terRecord',
				name: '法务服务-通话记录',
				meta: {
					auth: true,
					title: '通话记录'
				},
				component: () => import('@/pages/legalServices/terRecord'),
			},
			{
				path: 'cardSetting',
				name: '法务服务-卡号配置',
				meta: {
					auth: true,
					title: '卡号配置'
				},
				component: () => import('@/pages/legalServices/cardSetting'),
			},
			{
				path: 'contractLibrary',
				name: '合同库',
				meta: {
					auth: true,
					title: '合同库'
				},
				component: () => import('@/pages/legalServices/contractLibrary'),
			},
		]
	},
	// 企业法务
	{
		path: '/cl',
		name: '企业法务',
		meta: {
			auth: true,
			title: '企业法务'
		},
		redirect: {
			name: 'companyLegal'
		},
		component: layoutHeaderAside,
		children: [
			{
				path: 'companyLegal',
				name: '服务概况',
				meta: {
					auth: true,
					title: '服务概况'
				},
				component: () => import('@/pages/companyLegal/companyLegal')
			},
			{
				path: 'riskAssessment',
				name: '企业风险评估',
				meta: {
					auth: true,
					title: '企业风险评估'
				},
				component: () => import('@/pages/companyLegal/riskAssessment'),
			},
			{
				path: 'serviceRecord',
				name: '服务记录',
				meta: {
					auth: true,
					title: '服务记录'
				},
				component: () => import('@/pages/companyLegal/serviceRecord'),
			},
			{
				path: 'summarize',
				name: '服务总结',
				meta: {
					auth: true,
					title: '服务总结'
				},
				component: () => import('@/pages/companyLegal/summarize'),
			},
			{
				path: 'legalDocuments',
				name: '法务文书',
				meta: {
					auth: true,
					title: '法务文书'
				},
				component: () => import('@/pages/companyLegal/legalDocuments'),
			},
			{
				path: 'ca',
				name: '拖欠款催收',
				meta: {
					auth: true,
					title: '拖欠款催收'
				},
				component: () => import('@/pages/companyLegal/ca'),
			},
			{
				path: 'disputeManagement',
				name: '纠纷管理',
				meta: {
					auth: true,
					title: '纠纷管理'
				},
				component: () => import('@/pages/companyLegal/disputeManagement'),
			},
			{
				path: 'interviewRecord',
				name: '面谈记录',
				meta: {
					auth: true,
					title: '面谈记录'
				},
				component: () => import('@/pages/companyLegal/interviewRecord'),
			},
			// {
			// 	path: 'industryInformationIndex',
			// 	component: () => import('@/pages/companyLegal/industryInformationIndex'),
			// 	meta: {
			// 		auth: true
			// 	},
			// 	redirect: {
			// 		name: "行业资讯"
			// 	},
			// 	children: [
			// 		{
			// 			path: 'industryInformation',
			// 			name: '行业资讯',
			// 			meta: {
			// 				auth: true,
			// 				title: '行业资讯'
			// 			},
			// 			component: () => import('@/pages/companyLegal/industry/industryInformation'),
			// 		},
			// 		{
			// 			path: 'industryInformationDetials',
			// 			name: `行业资讯详情`,
			// 			component: () => import('@/pages/companyLegal/industry/industryInformationDetials'),
			// 			meta: {
			// 				auth: true,
			// 				title: '资讯详情'
			// 			}
			// 		}
			// 	]	
			// },
			{
				path: 'industryInformation',
				component: () => import('@/pages/companyLegal/industryInformationIndex'),
				meta: {
					auth: true,
					title: '行业资讯'
				},
				redirect: {
					name: "行业资讯列表"
				},
				children: [
					{
						path: 'index',
						name: "行业资讯列表",
						meta: {
							auth: true,
							title: '行业资讯列表'
						},
						component: () => import('@/pages/companyLegal/industry/industryInformation')
					},
					{
						path: 'industryInformationDetails',
						name: "资讯详情",
						meta: {
							auth: true,
							title: '资讯详情'
						},
						component: () => import('@/pages/companyLegal/industry/industryInformationDetials'),
					}
				]
			},
		]
	},
	// playground,
	// frame,
	// d2Crud,
	// plugins,
	// charts,
	// components,
	// element,
	// filters,
	// business,
	riskControl,
	systemMannage,
	enterprise,
	{
		path: '/valueAddedServices',
		name: '增值服务',
		component: layoutHeaderAside,
		meta: {
			auth: true,
			title: '增值服务'
		},
		redirect: {
			name: 'index'
		},
		children: [
			{
				path: 'index',
				name: "增值服务",
				meta: {
					auth: true,
					title: '增值服务'
				},
				component: () => import('@/pages/valueAddedServices/index')
			}
		]
	},
	{
		path: '/enterpriseClassroom',
		name: '企业课堂',
		component: layoutHeaderAside,
		meta: {
			auth: true,
			title: '企业课堂'
		},
		redirect: {
			name: '学习中心'
		},
		children: [
			{
				path: 'learningCenter',
				name: "学习中心",
				meta: {
					auth: true,
					title: '学习中心'
				},
				component: () => import('@/pages/enterpriseClassroom/learningCenter')
			},
			{
				path: 'moreCourses',
				name: "更多课程",
				meta: {
					auth: true,
					title: '更多课程'
				},
				component: () => import('@/pages/enterpriseClassroom/moreCourses')
			},
			{
				path: 'courseDetails',
				name: "课程详情",
				meta: {
					auth: true,
					title: '课程详情'
				},
				component: () => import('@/pages/enterpriseClassroom/courseDetails')
			},
			{
				path: 'testDetails',
				name: "考试详情",
				meta: {
					auth: true,
					title: '考试详情'
				},
				component: () => import('@/pages/enterpriseClassroom/testDetails')
			},
			{
				path: 'learningSituation',
				name: "我的学习情况",
				meta: {
					auth: true,
					title: '我的学习情况'
				},
				component: () => import('@/pages/enterpriseClassroom/learningSituation')
			},
			{
				path: 'dataStatistics',
				name: "数据统计",
				meta: {
					auth: true,
					title: '数据统计'
				},
				component: () => import('@/pages/enterpriseClassroom/dataStatistics')
			},
			{
				path: 'courseCenter',
				name: "课程中心",
				meta: {
					auth: true,
					title: '课程中心'
				},
				component: () => import('@/pages/enterpriseClassroom/courseCenter')
			},
			{
				path: 'classificationManagement',
				name: "分类管理",
				meta: {
					auth: true,
					title: '分类管理'
				},
				component: () => import('@/pages/enterpriseClassroom/classificationManagement')
			},
			{
				path: 'courseDetails1',
				name: "课程详情",
				meta: {
					auth: true,
					title: '课程详情'
				},
				component: () => import('@/pages/enterpriseClassroom/courseDetails1')
			},
			{
				path: 'courseDetails2',
				name: "课程详情",
				meta: {
					auth: true,
					title: '课程详情'
				},
				component: () => import('@/pages/enterpriseClassroom/courseDetails2')
			},
			{
				path: 'knowledgeMall',
				name: "知识商城",
				meta: {
					auth: true,
					title: '知识商城'
				},
				component: () => import('@/pages/enterpriseClassroom/knowledgeMall')
			}
		]
	},
	{
		path: '/pc',
		name: 'personalIndex',
		component: layoutHeaderAside,
		meta: {
			auth: false,
			title: '个人中心'
		},
		redirect: {
			name: 'personalCenter'
		},
		children: [
			{
				path: 'center',
				name: "personalCenter",
				meta: {
					auth: false,
					title: '个人信息'
				},
				component: () => import('@/pages/index/pc/center')
			},
			{
				path: 'interview',
				name: "personalInterview",
				meta: {
					auth: false,
					title: '个人信息'
				},
				component: () => import('@/pages/index/pc/interview')
			},
		]
	},
]

/**
 * 在主框架之外显示
 */
const frameOut = [
	// 登录
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login')
	},
	
]

/**
 * 错误页面
 */
const errorPage = [
	// 404
	{
		path: '*',
		name: '404',
		component: () => import('@/pages/error-page-404')
	}
]

/**
 * 扫码后界面
 */
const operation = [
	// 扫码后界面
	{
		path: '/fileForm/operation',
		name: 'operation',
		// redirect: 'fileForm',
		component: () => import('@/pages/fileForm/operation'),
		
	}
]

/**
 * 申请企业邀请码
 */
const applyCode = [
	// 扫码后界面
	{
		path: '/fileForm/applyCode',
		name: 'applyCode',
		// redirect: 'fileForm',
		component: () => import('@/pages/fileForm/applyCode'),
		
	}
]

/**
 * 扫码后用户信息页面
 */
 const userInfo = [
	// 扫码后界面
	{
		path: '/fileForm/userInfo',
		name: 'userInfo',
		// redirect: 'fileForm',
		component: () => import('@/pages/fileForm/userInfo'),
		
	}
]

const printingPage = [
	{
		path: '/work/post/printingPage',
		name: '职位信息打印',
		meta: {
			auth: false,
			name: "职位信息打印"
		},
		component: () => import('@/pages/work/employmentSettings/printingPage'),
	}
]

const printingTemplatePage = [
	{
		path: '/work/print/ptp',
		name: '通用协议',
		meta: {
			auth: false,
			name: "通用协议"
		},
		component: () => import('@/pages/work/LaborRiskControl/printTemplatePage'),
	}
]

const printingTemplatePageStaff = [
	{
		path: '/work/print/priviewContract',
		name: '合同预览打印',
		meta: {
			auth: false,
			name: "合同预览打印"
		},
		component: () => import('@/pages/work/LaborRiskControl/staffMsg/priviewContract'),
	}
]

const fdd = [
	{
		path: '/fdd/auth',
		name: 'fdd',
		meta: {
			auth: false,
			name: "法大大信息授权"
		},
		component: () => import('@/pages/fdd'),
	},
	{
		path: '/fdd/addAuth',
		name: 'addAuth',
		meta: {
			auth: false,
			name: "法大大信息授权"
		},
		component: () => import('@/pages/fdd/addAuth'),
	},
	{
		path: '/fdd/updateApper',
		name: 'updateApper',
		meta: {
			auth: false,
			name: "法大大变更企业管理员"
		},
		component: () => import('@/pages/fdd/updateApper'),
	},
	{
		path: '/sy/elc',
		name: 'syElc',
		meta: {
			auth: false,
			name: "电子合同"
		},
		component: () => import('@/pages/enterprise/added/elcNoValidate'),
	},
	{
		path: '/sy/oecNoValidate',
		name: 'syOecNoValidate',
		meta: {
			auth: false,
			name: "注册电子签约账号"
		},
		component: () => import('@/pages/enterprise/added/oecNoValidate'),
	},
	{
		path: '/sy/iframePageInfo',
		name: `syIframePageInfo`,
		component: () => import('@/pages/enterprise/added/iframePageInfoNoV'),
		meta: {
			auth: false,
			title: '企业信息授权'
		}
	},
	{
		path: '/sy/iframePage',
		name: `syIframePage`,
		component: () => import('@/pages/enterprise/added/iframePageNoV'),
		meta: {
			auth: false,
			title: '企业印章授权'
		}
	},
	{
		path: '/sy/contractDetails',
		name: `syContractDetails`,
		component: () => import('@/pages/enterprise/added/contractDetails'),
		meta: {
			auth: false,
			title: '合同详情'
		}
	},
]

const h5Pdf = [
	{
		path: '/index/pdf',
		name: 'indexPdf',
		meta: {
			auth: false,
			name: "pdf预览"
		},
		component: () => import('@/pages/index/pdf'),
	},
]

const helpCenter = [
	{
		path: '/helpCenter',
		name: 'helpCenter',
		meta: {
			auth: false,
			name: "帮助中心"
		},
		component: () => import('@/pages/enterprise/helpCenter'),
	},
	{
		path: '/printPage',
		name: `printPage`,
		component: () => import('@/pages/work/LaborRiskControl/staffMsg/printPage'),
		meta: {
			auth: false,
			title: '打印面试者信息'
		}
	},
	{
		path: '/printPageStaff',
		name: `printPageStaff`,
		component: () => import('@/pages/work/LaborRiskControl/staffMsg/printPageStaff'),
		meta: {
			auth: false,
			title: '打印员工信息'
		}
	},
	{
		path: '/backtoneReport',
		name: `backtoneReport`,
		component: () => import('@/pages/work/recruit/backtoneReport'),
		meta: {
			auth: false,
			title: '背景调查报告'
		}
	},
	{
		path: '/brThird',
		name: `brThird`,
		component: () => import('@/pages/work/recruit/brThird'),
		meta: {
			auth: false,
			title: '背景调查报告'
		}
	},
	{
		path: '/enterpriseSurveyReport',
		name: `enterpriseSurveyReport`,
		component: () => import('@/pages/contractCompliance/backtonePage/report'),
		meta: {
			auth: true,
			title: '企业背景调查报告详情'
		}
	},
	{
		path: '/ccm/eb/example',
		name: `enterprisebacktoneExample`,
		component: () => import('@/pages/contractCompliance/backtonePage/example'),
		meta: {
			auth: true,
			title: '企业背景调查示例'
		}
	},
	{
		path: '/priviewDoc',
		name: `priviewDoc`,
		component: () => import('@/pages/index/priviewDoc'),
		meta: {
			auth: true,
			title: '预览word文档'
		}
	}
]


// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
	...frameIn,
	...frameOut,
	...errorPage,
	...operation,
	...applyCode,
	...userInfo,
	...printingPage,
	...printingTemplatePage,
	...fdd,
	...printingTemplatePageStaff,
	...h5Pdf,
	...helpCenter
]
