// polyfill
import 'babel-polyfill'
// Vue
import Vue from 'vue'
import App from './App'
// store
import store from '@/store/index'
// 多国语
// import i18n from './i18n'
// 核心插件
import d2Admin from '@/plugin/d2admin'
import utils from './utils/downloadContractAttachment'
// [ 可选插件组件 ]D2-Crud
import D2Crud from '@d2-projects/d2-crud'
// [ 可选插件组件 ] 图表
import VCharts from 'v-charts'
// [ 可选插件组件 ] 右键菜单
import contentmenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'
// [ 可选插件组件 ] JSON 树状视图
import vueJsonTreeView from 'vue-json-tree-view'
// [ 可选插件组件 ] 网格布局组件
import {
	GridLayout,
	GridItem
} from 'vue-grid-layout';
import './assets/icon/iconfont.css';
import SplitPane from 'vue-splitpane';
import VueClipboard from 'vue-clipboard2';
// 菜单和路由设置
import router from './router';
import {
	menuAside,
	menuHeader
} from '@/menu';
import {
	frameInRoutes
} from '@/router/routes';
//引入echart1
import echarts from 'echarts';
import 'echarts/theme/shine.js';
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 引入按钮权限js
import utilsButtons from './utils/hasAuthorityButtons';
import downloadFile from './utils/downloadFileUtils';
import common from './utils/common';
import Print from 'vue-print-nb';
import { getValueItem } from '@/utils/localforageUtils';
Vue.use(Print);

Vue.prototype.url = process.env.NODE_ENV === 'development' ? 'https://api.o.soyin.cn' : 'https://api.o.soyin.cn';
Vue.prototype.$imgURL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001/' : 'https://soyin.oss-cn-beijing.aliyuncs.com/';
Vue.prototype.$imgURL1 = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001/' : 'https://soyin.oss-cn-beijing.aliyuncs.com/upload/soyin/';
Vue.prototype.nodeUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001' : 'https://api.node.soyin.cn';
Vue.prototype.loginUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001' : 'https://f.soyin.cn'; // 每次打包记得区分环境 http://test.f.soyin.cn  https://f.soyin.cn
Vue.prototype.riskAssessmentUrl = process.env.NODE_ENV === 'development' ? 'http://hpb.natapp1.cc/' : 'https://h5.wx.soyin.cn/';  // 每次打包记得区分环境 http://test.wx.soyin.cn/  https://h5.wx.soyin.cn/
Vue.prototype.databaseFileDoMain = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001/' : 'https://f.block.soyin.cn/';
Vue.prototype.$http = common.http;	// 公共请求器
Vue.prototype.$echarts = echarts; 	// 图表
Vue.prototype.axios = common.axios;	// axios
Vue.prototype.baseDownloadUrl = 'https://soyin.oss-cn-beijing.aliyuncs.com/upload/soyin/'; // 域名
Vue.prototype.formatNYR = common.formatNYR; // 格式化年月日
Vue.prototype.fileAccept = common.fileAccept;// 文件上传格式限制
Vue.prototype.validationIsEmpty = common.validationIsEmpty;// 验证字段信息为空
Vue.prototype.formatDataTime = common.formatDataTime;// 格式化年月日时分秒
Vue.prototype.formatMinTimeValue = common.formatMinTimeValue;// 格式化年月日时分
Vue.prototype.$getNextMonthDate = common.getNextMonthDate;// 获取下个月的日期
Vue.prototype.getValueItem = getValueItem;
Vue.prototype.regPhone = /^1(3|4|5|6|7|8|9)\d{9}$/;
Vue.prototype.$downloadContractAttachment = utils.downloadKeyFile; // 下载指定的文件
Vue.prototype.$hasAuthorityButtons = utilsButtons.hasAuthorityButtons; // 权限控制按钮
Vue.prototype.$downloadFileUtils = downloadFile.downloadFile; // 下载文件
Vue.prototype.$getPriviewDocx = downloadFile.getPriviewDocx; // 在线预览 doc,docx
Vue.prototype.$openPriviewDoc = downloadFile.openPriviewDoc; // 打开窗口预览

Vue.directive('down', {
	inserted: (el, binding) => {
		el.style.cssText = 'cursor: pointer;color:write;'
		el.addEventListener('click', () => {
			let array = binding.value.split(",")
			for (let item of array) {
				let url = Vue.prototype.baseDownloadUrl + item
				window.open(url);
			}
		})
	}
});

// 核心插件
Vue.use(d2Admin);
Vue.use(VueClipboard);
// 可选插件组件
Vue.use(D2Crud);
Vue.use(VCharts);
Vue.use(contentmenu);
Vue.use(vueJsonTreeView);
Vue.use(VueQuillEditor);

Vue.component('d2-grid-layout', GridLayout);
Vue.component('d2-grid-item', GridItem);
Vue.component('SplitPane', SplitPane);

Vue.filter('formatDateTime', common.formatDataTime);
Vue.filter('formatDateNYRTime', common.formatNYR);
Vue.filter('formatMinTimeValue', common.formatMinTimeValue);

new Vue({
	router,
	store,
	// i18n,
	render: h => h(App),
	created() {
		this.$store.commit('d2admin/page/init', frameInRoutes)
		this.$store.commit('d2admin/menu/headerSet', menuHeader)
		this.$store.commit('d2admin/menu/asideSet', menuHeader)
	},
	mounted() {
		// 展示系统信息
		// this.$store.commit('d2admin/releases/versionShow')
		// 用户登录后从数据库加载一系列的设置
		this.$store.dispatch('d2admin/account/load')
		// 获取并记录用户 UA
		this.$store.commit('d2admin/ua/get')
		// 初始化全屏监听
		this.$store.dispatch('d2admin/fullscreen/listen')
	},
	watch: {
		'$route.matched': {
			handler(matched) {
				if (matched.length > 0) {
					// bug 销售雷达前三个菜单第一次点击会选中话术库
					const _side = menuAside.filter(menu => menu.path === matched[0].path)
					this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : [])
				}
			},
			immediate: true
		}
	}
}).$mount('#app')
