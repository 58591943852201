/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:54
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-04-18 14:07:26
 */
import { Message } from 'element-ui'
import axios from "axios";
/**
 * 
 * @param {*} contractUrl : 下载的文件路径
 * @param {*} fileName : 文件名
 */
const downloadFile = (contractUrl, fileName) => {
    axios.get(contractUrl, {
        responseType: "blob", //返回数据的格式，可选值为arraybuffer,blob,document,json,text,stream，默认值为json
    }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName
        a.click();
        window.URL.revokeObjectURL(url);
        return true
    }).catch((err) => {
        Message.error('下载失败,请稍后重试!')
        return false
    });
}

/**
 * @desc 预览doc,docx文件
 * @param {*} contractUrl 预览的文件路径
 */
const getPriviewDocx = (contractUrl) => {
    return new  Promise((reject) => {
        axios.get(contractUrl, {
            responseType: "blob", //返回数据的格式，可选值为arraybuffer,blob,document,json,text,stream，默认值为json
        }).then((response) => {
            reject(response.data);
        }).catch((err) => {
            Message.error('下载失败,请稍后重试!')
            reject(false);
        });
    })
}

/**
 * 
 * @param {*} router 路由对象
 * @param {*} contractUrl 需要打开的doc url
 */
const openPriviewDoc = (router, contractUrl) => {
    let pathInfo = router.resolve({
        path: '/priviewDoc',
        query: {
            docUrl: contractUrl
        }
    })
    window.open(pathInfo.href, '_blank');
}

export default {
    downloadFile,
    getPriviewDocx,
    openPriviewDoc
}
