import Vue from 'vue'
import VueRouter from 'vue-router'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index'
import util from '@/libs/util.js'
import routes from './routes'
import { getItem } from '@/utils/localforageUtils'
import axios from 'axios'

Vue.use(VueRouter)
// 导出路由 在 main.js 里使用
const router = new VueRouter({
	routes
})
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:7001' : 'https://api.node.soyin.cn'

/**
 * @desc 获取阿里云临时访问凭证
 * @param {String} authStaffToken: 员工令牌
 * @param {String} token: 用户令牌
 * @return {Object} 
 */
const getAliOssSTS = async (authStaffToken, token) => {
	let cfg = {
		url: baseUrl + '/v1/api/getAliOssSTS',
		method: 'GET',
		headers: {}
	}
	cfg.headers['staff-auth-token'] = authStaffToken || ''
	cfg.headers['auth-token'] = token
	let res = await axios(cfg)
	if (res.data.code === 0) {
		const ossRes = res.data.credentials;
		if (ossRes.AccessKeyId && ossRes.AccessKeySecret) {
			// 存在vuex里
			store.dispatch("d2admin/user/setAliOssSTS", ossRes);
		}
	} else {
		return {
			AccessKeyId: null
		};
	}
};
// const routerRefreshTime = store.state.d2admin.menu.routerRefreshTime
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
	// 进度条
	NProgress.start();
	// 关闭搜索面板
	store.commit('d2admin/search/set', false);
	const aliOssSTS = await store.dispatch('d2admin/user/getAliOssSTS');
	// 验证当前路由所有的匹配中是否需要有登录验证的
	if (to.matched.some(r => r.meta.auth)) {
		const token = await getItem('authToken')
		const authStaffToken = await getItem('staffAuthToken')
		if (token && authStaffToken) {
			store.commit('d2admin/telephonySupport/setStaffAuthToken', authStaffToken);
			// 获取阿里云oss临时访问凭证
			if (aliOssSTS && aliOssSTS.AccessKeyId && aliOssSTS.AccessKeySecret) {
				// 判断是否过期, 过期重新请求
				const Expiration = aliOssSTS.Expiration;
				if (new Date(Expiration).getTime() - new Date().getTime() <= 0) {
					await getAliOssSTS(authStaffToken, token);
				}
			} else {
				await getAliOssSTS(authStaffToken, token);
			}
			next()
		} else {
			// 没有登录的时候跳转到登录界面
			// 携带上登陆成功之后需要跳转的页面完整路径
			next({
				name: 'login',
				query: {
					// redirect: to.fullPath
				}
			})
			// https://github.com/d2-projects/d2-admin/issues/138
			NProgress.done()
		}
	} else {
		// 不需要身份校验 直接通过
		next()
	}
})

router.afterEach(to => {
	// 进度条
	NProgress.done()
	// 多页控制 打开新的页面
	store.dispatch('d2admin/page/open', to)
	// 更改标题
	util.title(to.meta.title)
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {})
}

export default router
