export default {
  path: '/cl',
  title: '企业法务',
  // icon: 'none',
  iconfont: 'icon-menu-xingzhengfawu',
  children: (pre => [
    { path: `${pre}companyLegal`, title: '服务概况',iconfont: 'icon-menu-gaikuang' },
    { path: `${pre}riskAssessment`, title: '企业风险评估',iconfont: 'icon-menu-risk'},
    { path: `${pre}serviceRecord`, title: '服务记录',iconfont: 'icon-menu-fuwu'},
    { path: `${pre}summarize`, title: '服务总结',iconfont: 'icon-menu-zongjie'},
    { path: `${pre}legalDocuments`, title: '法务文书',iconfont: 'icon-menu-wenshu'},
    { path: `${pre}ca`, title: '拖欠款催收',iconfont: 'el-icon-set-up'},
    { path: `${pre}disputeManagement`, title: '纠纷管理',iconfont: 'icon-menu-maodunjiufen'},
    { path: `${pre}interviewRecord`, title: '面谈记录',iconfont: 'icon-menu-tubiao-' },
    { path: `${pre}industryInformation`, title: '行业资讯', iconfont: 'icon-menu-xingzhengfawu' },
    // {
    //   title: '表格',
    //   icon: 'table',
    //   children: [
    //     { path: `${pre}table/1`, title: '表格 1' }
    //   ]
    // },
    // {
    //   title: 'ISSUES',
    //   icon: 'github',
    //   children: [
    //     { path: `${pre}issues/142`, title: '#142' }
    //   ]
    // }
  ])('/cl/')
}
