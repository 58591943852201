import layoutHeaderAside from '@/layout/header-aside'

export default {
	path: '/cy',
	name: 'cy',
	meta: {
		auth: true,
		title: '合同库'
	},
	component: layoutHeaderAside,
    children: (pre => [
        {
			path: 'index',
			name: `合同库`,
			component: () => import('@/pages/contractLibrary/index'),
			meta: {
				auth: true,
				title: '合同库'
			},
			redirect: { name: 'clHome' }
        },
		{
			path: 'hm',
			name: `clHome`,
			component: () => import('@/pages/contractLibrary/index/home'),
			meta: {
				auth: true,
				title: '合同库首页'
			},
		},
		{
			path: 'sh',
			name: `clSearch`,
			component: () => import('@/pages/contractLibrary/index/search'),
			meta: {
				auth: true,
				title: '搜索页'
			},
		},
		{
			path: 'ds',
			name: `clDetails`,
			component: () => import('@/pages/contractLibrary/index/details'),
			meta: {
				auth: true,
				title: '合同详情'
			},
		},
        {
			path: 'mb',
			name: `myBrowsing`,
			component: () => import('@/pages/contractLibrary/myBrowsing'),
			meta: {
				auth: true,
				title: '我的浏览'
			}
        },
        {
			path: 'mc',
			name: `myCollection`,
			component: () => import('@/pages/contractLibrary/myCollection'),
			meta: {
				auth: true,
				title: '我的收藏'
			}
        },
        {
			path: 'md',
			name: `myDownloads`,
			component: () => import('@/pages/contractLibrary/myDownloads'),
			meta: {
				auth: true,
				title: '我的下载'
			}
		},
	])('cy-')
}
