<template>
  <div class="leftLayout">
    <el-container>
      <el-aside width="300px">
        <d2-menu-side />
      </el-aside>
      <el-container>
        <el-header>
          <div
            class="d2-layout-header-aside-group top_box"
            style="background: #fff"
          >
            <div class="d2-layout-header-aside-mask"></div>
            <div class="d2-layout-header-aside-content top_menu" flex="dir:top">
              <div
                class="d2-theme-header menu_header"
                :style="{
                  opacity: 1,
                }"
                flex
              >
                <!-- <div class="header_name">
                    {{companyName}}
                  </div> -->
                <d2-menu-header></d2-menu-header>
                <div class="d2-header-right">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="帮助中心"
                    placement="top-start"
                  >
                    <img
                      src="../../assets/6/help-icon.png"
                      style="width: 25px; height: 25px; background: #3399ff"
                      class="user_hearder"
                      @click="goHelpCenter"
                    />
                  </el-tooltip>
                  <el-dropdown @command="handleCommand">
                    <div
                      class="el-dropdown-link"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="../../assets/image/default.png"
                        style="width: 30px; height: 30px"
                        v-if="!user.headPortrait"
                        class="user_hearder"
                      />
                      <img
                        :src="$imgURL + user.headPortrait"
                        style="width: 30px; height: 30px"
                        v-else
                        class="user_hearder"
                      />
                      <i class="el-icon-arrow-down" style="font-size: 20px"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="1"
                        ><i class="el-icon-user" style="font-size: 16px"></i>
                        切换账号</el-dropdown-item
                      >
                      <el-dropdown-item :command="2"
                        ><i
                          class="el-icon-switch-button"
                          style="font-size: 16px"
                        ></i>
                        退出登录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <!-- <div class="header_user">{{ user.staffName }}</div> -->
                  <!-- <el-button
                    type="text"
                    style="color: #333"
                    icon="el-icon-switch-button"
                    @click="logOff"
                    >退出</el-button
                  > -->
                  <!-- <d2-header-fullscreen />
                  <d2-header-theme /> -->
                  <!-- <d2-header-user/> -->
                </div>
              </div>
            </div>
          </div>
        </el-header>
        <el-main class="main-box">
          <el-backtop target=".main-box">
            <i class="el-icon-caret-top"></i>
          </el-backtop>
          <div class="d2-theme-container-main-body" flex-box="1">
            <div class="crumbs">
              <d2Breadcrumb></d2Breadcrumb>
            </div>
            <transition :name="transitionActive ? 'fade-transverse' : ''">
              <keep-alive :include="keepAlive">
                <router-view :key="key"></router-view>
              </keep-alive>
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
  <!-- <div
    class="d2-layout-header-aside-group"
    :style="styleLayoutMainGroup"
    :class="{grayMode: grayActive}"> -->
  <!-- 半透明遮罩 -->
  <!-- <div class="d2-layout-header-aside-mask"></div> -->
  <!-- 主体内容 -->
  <!-- <div class="d2-layout-header-aside-content" flex="dir:top"> -->
  <!-- 顶栏 -->
  <!-- <div
        class="d2-theme-header"
        :style="{
          opacity: this.searchActive ? 0.5 : 1
        }"
        flex-box="0"
        flex>
        <div class="logo-group" :style="{width: asideCollapse ? asideWidthCollapse : asideWidth}" flex-box="0">
          <img v-if="asideCollapse" :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/icon-only.png`">
          <img v-else :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/all.png`">
        </div>
        <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0">
          <d2-icon name="bars"/>
        </div>
        <d2-menu-header flex-box="1"/> -->
  <!-- 顶栏右侧 -->
  <!-- <div class="d2-header-right" flex-box="0"> -->
  <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
  <!-- <d2-header-search @click="handleSearchClick"/>
          <d2-header-error-log/>
          <d2-header-fullscreen/>
          <d2-header-theme/>
          <d2-header-size/>
          <d2-header-user/>
        </div>
      </div> -->
  <!-- 下面 主体 -->
  <!-- <div class="d2-theme-container" flex-box="1" flex> -->
  <!-- 主体 侧边栏 -->
  <!-- <div
          flex-box="0"
          ref="aside"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }">
          <d2-menu-side/>
        </div> -->
  <!-- 主体 -->
  <!-- <div class="d2-theme-container-main" flex-box="1" flex> -->
  <!-- 搜索 -->
  <!-- <transition name="fade-scale">
            <div v-show="searchActive" class="d2-theme-container-main-layer" flex="dir:top">
              <d2-panel-search
                ref="panelSearch"
                @close="searchPanelClose"/>
            </div>
          </transition> -->
  <!-- 内容 -->
  <!-- <transition name="fade-scale">
            <div v-show="!searchActive" class="d2-theme-container-main-layer" flex="dir:top"> -->
  <!-- tab -->
  <!-- <div class="d2-theme-container-main-header" flex-box="0">
                <d2-tabs/>
              </div> -->
  <!-- 页面 -->
  <!-- <div class="d2-theme-container-main-body" flex-box="1">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :include="keepAlive">
                    <router-view/>
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import d2MenuSide from "./components/menu-side";
import d2MenuHeader from "./components/menu-header";
import d2Tabs from "./components/tabs";
import d2HeaderFullscreen from "./components/header-fullscreen";
import d2HeaderSearch from "./components/header-search";
import d2HeaderSize from "./components/header-size";
import d2HeaderTheme from "./components/header-theme";
import d2HeaderUser from "./components/header-user";
import d2HeaderErrorLog from "./components/header-error-log";
import d2Breadcrumb from "./components/breadcrumb/index";
import { mapState, mapGetters, mapActions } from "vuex";
import mixinSearch from "./mixins/search";
import { removeItem } from '@/utils/localforageUtils';
export default {
  name: "d2-layout-header-aside",
  mixins: [mixinSearch],
  components: {
    d2MenuSide,
    d2MenuHeader,
    d2Tabs,
    d2HeaderFullscreen,
    d2HeaderSearch,
    d2HeaderSize,
    d2HeaderTheme,
    d2HeaderUser,
    d2HeaderErrorLog,
    d2Breadcrumb,
  },
  data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: "200px",
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: "65px",
      companyName: "",
      user: {},
    };
  },
  async created() {
    this.user = await this.getValueItem("staffInfo");
    if (!this.user) {
      await removeItem("staffAuthToken");
      await removeItem("companyMsg");
      await removeItem("isInterview");
      await removeItem("staffInfo");
      await removeItem("companyId");
      this.$router.push("/login");
      return;
    }
    let company = await this.getValueItem("companyMsg");
    this.companyName = company ? company.companyName : '';
  },
  computed: {
    key() {
      return this.$route.fullPath + Math.random();
    },
    ...mapState("d2admin", {
      keepAlive: (state) => state.page.keepAlive,
      grayActive: (state) => state.gray.active,
      transitionActive: (state) => state.transition.active,
      asideCollapse: (state) => state.menu.asideCollapse,
    }),
    ...mapGetters("d2admin", {
      themeActiveSetting: "theme/activeSetting",
    }),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup() {
      return {
        ...(this.themeActiveSetting.backgroundImage
          ? {
              backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`,
            }
          : {}),
      };
    },
    ...mapState("d2admin/user", ["info"]),
  },
  methods: {
    goHelpCenter() {
      let { href } = this.$router.resolve({ path: "/helpCenter" });
      window.open(href, "_blank");
    },
    handleCommand(e) {
      if (e == 2) {
        // 退出登录
        this.logOff();
      } else {
        this.changeUser();
      }
    },
    ...mapActions("d2admin/menu", ["asideCollapseToggle"]),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle();
    },
    ...mapActions("d2admin/account", ["logout", "changeAccount"]),
    /**
     * @description 登出
     */
    changeUser() {
      this.changeAccount({
        vm: this,
        confirm: true,
      });
    },
    logOff() {
      this.logout({
        vm: this,
        confirm: true,
      });
    },
  },
};
</script>

<style lang="scss">
// 注册主题
@import "~@/assets/style/theme/register.scss";
::v-deep.d2-layout-header-aside-group {
  height: 58px;
}
.crumbs {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: left;
  width: 87%;
  padding: 10px 0 17px 18px;
  max-width: 87%;
}
.leftLayout {
  width: 100%;
  height: 100vh;
  background-color: #e9eef3;
  box-sizing: border-box;
  overflow: hidden;
}
.top_box {
  height: 58px;
  // border-radius: 16px;
  line-height: 58px;
  box-shadow: 0px 0px 5px #ccddee;
  // z-index: 999;
  // margin-bottom: 16px;
}
.el-container {
  // height: 100vh;
  height: 100%;
  box-sizing: border-box;
}
.el-container.is-vertical {
  box-sizing: border-box !important;
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  height: 58px !important;
  // margin-bottom: 16px;
  padding: 0 0;
}

.d2-header-right {
  height: 58px !important;
  color: #000;
  // margin-right: 80px;
  .header_user {
    font-size: 14px;
    color: #333;
    margin-right: 20px;
  }
  .user_hearder {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #b3c0d1;
    margin-right: 12px;
  }
}
.el-aside {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  height: 100% !important;
  overflow: hidden;
  z-index: 1000;
  // margin-right: 16px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  // text-align: center;
  box-sizing: border-box;
  height: calc(100vh - 80px - 48px);
  width: 100%;
  padding: 16px 80px;
  overflow-x: hidden;
  overflow-y: auto;
}
.main-box {
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  // justify-content: center;
}
.top_menu {
  width: 100%;
  // border: 1px solid blue;
  box-sizing: border-box;
}
.menu_header {
  color: #333 !important;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.main-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.main-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.main-box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.header_name {
  margin-left: 20px;
  font-weight: 600;
  color: #000;
  font-size: 24px;
}
.theme-line .d2-theme-header .el-menu .el-menu-item.is-active {
  box-sizing: border-box;
  background: none;
  border-bottom: 6px solid #60afff;
}
</style>
