/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:54
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-06-28 13:56:43
 */
import { getValueItem } from '@/utils/localforageUtils';
/*
 * 权限按钮控制
 * key： 每个按钮的特殊数字
 */
const hasAuthorityButtons = async (key) => { 
    let buttons = await getValueItem('menu_buttons');
    if (!buttons) {
        return false // false为隐藏按钮
    } else {
        let authorityFlag = true // 该值改成false, 即可恢复所有按钮
        if (authorityFlag) {
            return buttons.indexOf(key) > -1 ? true : false
        } else {
            return true
        }
    }
}

export default {
    hasAuthorityButtons
}