/*
 * @Author: your name
 * @Date: 2021-10-20 09:41:54
 * @LastEditTime: 2021-12-15 12:00:25
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \soyin_node\sy-client\src\router\modules\systemMannage.js
 */
import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
// const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const meta = { auth: true }

export default {
  path: '/system',
  name: 'system',
  meta: {
    auth: true,
    title: '系统管理'
  },
  redirect: { name: 'system' },
  component: layoutHeaderAside,
  children: (pre => [
    {
      path: 'role',
      name: `${pre}role`,
      component:() => import('@/pages/system/role'),
      meta: {
        ...meta,
        title: '角色权限'
      }
    },
    {
      path: 'admin/view',
      name: `${pre}admin-view`,
      component:() => import('@/pages/system/admin/view'),
      meta: {
        ...meta,
        title: '用户管理'
      }
    },
    {
      path: 'admin/add',
      name: `${pre}admin-add`,
      component:() => import('@/pages/system/admin/add'),
      meta: {
        ...meta,
        title: '添加用户'
      }
    },
    {
      path: 'message',
      name: `${pre}message`,
      component:() => import('@/pages/system/message'),
      meta: {
        ...meta,
        title: '短信中心'
      }
    },
    // {
    //   path: 'editAdmin',
    //   name: `${pre}editAdmin`,
    //   component: _import('demo/systemManage/editAdmin'),
    //   meta: {
    //     ...meta,
    //     title: '添加管理员'
    //   }
    // },
  ])('system-')
}
