export default {
    path: '/enterprise',
    title: '企业中心',
    // icon: 'none',
    iconfont: 'icon-menu-user',
    children: (pre => [
        { path: `${pre}info`, title: '企业信息', icon: 'el-icon-s-custom', iconfont: 'el-icon-s-custom' },
        { path: `${pre}system`, title: '权限管理', icon: 'el-icon-s-claim', iconfont: 'el-icon-user-solid', 
          children: (pree => [
            { path: `${pree}account`, title: '账号管理', icon: 'user', iconfont: 'el-icon-user-solid' },
            { path: `${pree}role`, title: '角色权限', icon: 'user', iconfont: 'el-icon-s-opportunity' }
          ])('/enterprise/system/')
        },
        { path: `${pre}added`, title: '增值服务', icon: 'el-icon-document-add', iconfont: 'el-icon-s-check', 
          children: (pree => [
            { path: `${pree}elc`, title: '电子合同', icon: 'user', iconfont: 'el-icon-s-check' },
            { path: `${pree}msg`, title: '短信服务', icon: 'user', iconfont: 'el-icon-s-comment' }
          ])('/enterprise/added/')
        },
        // { path: `${pre}oec`, title: '注册电子签约账号', icon: 'user', iconfont: 'icon-menu-quanxian' }
    ])('/enterprise/')
  }
    