// // 插件
// import demoPlugins from './modules/demo-plugins'
// // 组件
// import demoComponents from './modules/demo-components'
// // 组件
// import demoCharts from './modules/demo-charts'
// // 组件库
// import demoElement from './modules/demo-element'
// // 组件库
// import demoFilters from './modules/demo-filters'
// // 试验台
// import demoPlayground from './modules/demo-playground'
// // 示例
// import demoBusiness from './modules/demo-business'
// // CRUD
// import demoD2Crud from './modules/demo-d2-crud'
// // 第三方网页
// import demoFrame from './modules/demo-frame'
//用工风控
import demoRiskControl from './modules/demo-riskControl'
// 人事设置
import employmentSettings from './modules/employmentSettings'
//系统设置
// import systemManage from './modules/systemManage'
// 合同库
import cly from './modules/contractLibrary'
//合同履约
import contractCompliance from './modules/contractCompliance'
//客户营销
import customerMarketing from './modules/customerMarketing'
//法务服务
import legalServices from './modules/legalServices'
//企业法务
import companyLegal from './modules/companyLegal'
// 我的惬意
import enterprise from './modules/enterprise'
// 个人中心
import personal from './modules/personal'
// 增值服务
import valueAddedServices from './modules/valueAddedServices'
// 企业课堂
import enterpriseClassroom from './modules/enterpriseClassroom'
// 菜单 侧边栏
export const menuAside = [
  // demoComponents,
  // demoPlugins,
  // demoCharts,
  // demoElement,
  // demoFilters,
  // demoPlayground,
  // demoBusiness,
  // demoD2Crud,
  // demoFrame,
  demoRiskControl,
  employmentSettings,
  // systemManage,
  contractCompliance,
  cly,
  customerMarketing,
  legalServices,
  companyLegal,
  enterprise,
  enterpriseClassroom,
  valueAddedServices,
  personal
]
// 菜单 顶栏
export const menuHeader = [
  // {
  //   path: '/index',
  //   title: '首页',
  //   icon: 'home',
  // },
  demoRiskControl,
  // {
  //   title: '用工风控',
  //   icon: 'puzzle-piece',
  //   children: (pre => [
  //     { path: `${pre}role`, title: '角色权限', icon: 'home',
  //       children: [

  //       ]
  //     },
  //     { path: `${pre}admin/view`, title: '用户管理', icon: 'home' },
  //     { path: `${pre}message`, title: '短信中心', icon: 'home' },
  //     // {
  //     //   title: '管理员管理',
  //     //   icon: 'table',
  //     //   children: [
  //     //     { path: `${pre}adminManage`, title: '管理员管理' },
  //     //     { path: `${pre}editAdmin`, title: '添加管理员' },
  //     //   ]
  //     // },
  //     // {
  //     //   title: '企业邀请码管理',
  //     //   icon: 'table',
  //     //   children: [
  //     //     { path: `${pre}customerApply`, title: '客户申请' },
  //     //     { path: `${pre}inviteCode`, title: '邀请码管理' },
  //     //   ]
  //     // }
  //   ])('/demo/')
  // },
  contractCompliance,
  cly,
  enterpriseClassroom,
  customerMarketing,
  legalServices,
  companyLegal,
  // systemManage,
  enterprise,
  personal,
  valueAddedServices
  // demoPlayground,
  // demoBusiness,
]
