<!--
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2021-10-20 09:41:52
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-02-08 14:46:02
-->
<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <el-breadcrumb-item v-for="(m, index) in matchedArr" :key="index">
      <span v-if="index <= 0"> {{ m.meta.title }}</span>
      <span v-else style="color: #3399ff; cursor: pointer" @click="goPage(m)">
        {{ m.meta.title }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "d2-breadcrumb",
  created() { },
  methods: {
    goPage(m) {
      if (m.name === "服务信息" || m.name === "serviceRecord" || (m.meta.title === '资源库人员信息' || m.meta.title === '面试人员信息' || m.meta.title === '花名册人员信息')) {
        this.$router.go(-1);
      } else if (m.name === "企业信息" || m.name === "follewSituation") {
        if (m.name === "follewSituation") {
          return;
        }
        this.$router.go(-1);
      } else if (m.name === "电话录音") {
        this.$router.push("/cm/tr/ter");
      } else if (m.path === "/work/lrc/es") {
        this.$router.push("/work/lrc/es/personnelProcess");
      } else {
        this.$router.push(m.path);
      }
    },
  },
  computed: {
    matchedArr() {
      let temp = [],
        temps = [];
      this.$route.matched.filter((item, index, self) => {
        if (item) {
          temp.push(item);
        }
      });
      temp.filter((item, index, self) => {
        if (!temps.includes(item)) {
          temps.push(item);
        }
      });
      return temps;
    },
  },
};
</script>

<style>
.d2-contextmenu {
  position: absolute;
  padding: 5px 0;
  z-index: 2018;
  background: #fff;
  border: 1px solid #cfd7e5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
