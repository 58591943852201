import { render, staticRenderFns } from "./d2-source.vue?vue&type=template&id=2f9927f4&scoped=true"
import script from "./d2-source.vue?vue&type=script&lang=js"
export * from "./d2-source.vue?vue&type=script&lang=js"
import style0 from "./d2-source.vue?vue&type=style&index=0&id=2f9927f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9927f4",
  null
  
)

export default component.exports