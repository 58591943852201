/*
 * @Description: soyin.cn
 * @Version: 1.0
 * @Author: hpb95@sina.com
 * @Date: 2023-06-27 11:21:34
 * @LastEditors: hpb95@sina.com
 * @LastEditTime: 2023-06-29 09:20:43
 */
import localForage from 'localforage';

/**
 * @desc 设置localForage某一项
 * @param {*} key 
 * @param {*} value 
 */
export const setItem = (key, value) => {
    return new Promise((reject) => {
        localForage.setItem(key, value).then(function () {
            reject(true);
        }).catch(function (err) {
            reject(false);
            alert('当前浏览器不支持localForage,请更换浏览器!');
        });
    })
}

/**
 * @desc 获取localForage某一项
 * @param {*} key 
 */
export const getItem = (key) => {
    return localForage.getItem(key);
}

/**
 * @desc 清空localForage某一项
 * @param {*} key 
 */
export const removeItem = (key) => {
    return new Promise((reject) => {
        localForage.removeItem(key).then(function () {
            reject(true);
        }).catch(function (err) {
            reject(false);
            alert('当前浏览器不支持localForage,请更换浏览器!');
        });
    })
}

/**
 * @desc 清空localForage
 */
export const clearlocalForage = () => {
    return new Promise((reject) => {
        localForage.clear().then(function () {
            reject(true);
        }).catch(function (err) {
            reject(false);
            alert('当前浏览器不支持localForage,请更换浏览器!');
        });
    })
}

/**
 * @desc 异步获取指定key
 */
export async function getValueItem(key) {
    return await localForage.getItem(key);
}