export default {
    path: '/enterpriseClassroom',
    title: '企业课堂',
    // icon: 'none',
    iconfont: 'icon-menu-user',
    children: (pre => [
        { path: `${pre}learningCenter`, title: '学习中心', icon: 'user', iconfont: 'el-icon-notebook-1' },
        { path: `${pre}dataStatistics`, title: '数据统计', icon: 'user', iconfont: 'el-icon-notebook-1' },
        { path: `${pre}courseCenter`, title: '课程中心', icon: 'user', iconfont: 'el-icon-notebook-1' },
        { path: `${pre}knowledgeMall`, title: '知识商城', icon: 'user', iconfont: 'el-icon-notebook-1' },
    ])('/enterpriseClassroom/')
}